import React from "react";
import { Redirect } from "react-router";

const SignatureRedirect = () => {
    const pId = window.location.search.split("=")[1];
    return <Redirect to={{
        pathname: "/signature",
        state: {
            projectId: pId
        }
    }}/>
}

export default SignatureRedirect;