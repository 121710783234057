import React from "react";

const Button = (props) => {
    return(
        <>
            <button id={props.id} style={props.style} onClick={props.onClick} className={props.className}>{props.name}</button>
        </>
    );
}

export default Button;