import React from "react";

const Ahref = (props) => {
    return(
        <>
            <a id={props.id} href={props.href} style={props.style} onClick={props.onClick}>{props.name}</a>
        </>
    );
}

export default Ahref;