import React from "react";
import styled from "styled-components";
import { CustomerOrga } from "../../organisms";

const CustomerTemplate = ({ customerId }) => {
    return (
        <Container>
            <CustomerOrga customerId={ customerId }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default CustomerTemplate;