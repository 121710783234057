import React from "react";
import { Redirect } from "react-router";
import { CustomerProjectTemplate } from "../../components/templates";

const CustomerProject = props => {
    let projectType = "1";
    if(props.location){
        if(props.location.state){
            projectType = props.location.state.projectType || projectType;
        }
    }
    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404" />
    }else{
        return (
            <>
                <CustomerProjectTemplate projectType={ projectType }/>
            </>
        )
    }
}

export default CustomerProject;