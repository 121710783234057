import axios from "axios";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Li } from "../../atoms";

const BtnCustomer = ({ projectType }) => {

    localStorage.setItem("typeProject", projectType);
    const tokenHeader = localStorage.getItem("tokenHeader");
    const [typeUser, setTypeUser] = React.useState("");

    useEffect(() => {
        const fetchCustomer = () => {
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                setTypeUser(response.data.type);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        window.alert("Une erreur est survenue.");
                        window.location = window.location.origin + "/404";
                    }
                }
            });
        };

        fetchCustomer();
    }, [tokenHeader]);

    const handleBack = () => {
        window.location = window.location.origin;
    }

    return(
        <Container>
            <Ul>
            {(() => {
                if(typeUser === "tech"){
                    return(
                        <>
                            <Li name="Nouveau client" href="/newCustomer"/>
                            <Li name="Client existant" href="/choiceCustomer"/>
                            <Li name="Retour" href="#" onClick={handleBack}/>
                        </>
                    )
                }else if(typeUser === ""){
                    return(
                        <>
                        </>
                    )
                }else{
                    window.alert("Vous n'êtes pas autorisé à effectuer cette action.");
                    window.location = window.location.origin;
                }
            })()}
            </Ul>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin: auto;
    font-size: 25px;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 75px;

    @media screen and (max-width: 750px){
        font-size: 15px;
    }
`;

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 5%;

    li{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 5%;
    }

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5%;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
`;

export default BtnCustomer;