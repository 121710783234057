import React from "react";
import { Redirect } from "react-router";

const ProjectsRedirectStatus = () => {
    const status = window.location.search.split("=")[1];
    return (
        <Redirect to={{
        pathname: "/projects",
        state: {
            status: status
        }
    }}/>)
}

export default ProjectsRedirectStatus;