import React from "react";
import { Redirect } from "react-router";

const NewProjectRedirect = () => {
    const v = window.location.search.split("?")[1];
    const tmp = v.split("&");
    const cId = tmp[0].split("=")[1];
    let pId = "0"
    if(tmp[1]){
        pId = tmp[1].split("=")[1];
    }
    return <Redirect to={{
        pathname: "/newProject",
        state: {
            customerId: cId,
            projectId: pId
        }
    }}/>
}

export default NewProjectRedirect;