import React, { useEffect } from "react";
import { Button, Input, Label, Select } from "../../atoms";
import styled from "styled-components";
import axios from 'axios';

const FormNewCompte = () => {

    const tokenHeader = localStorage.getItem("tokenHeader");

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [verifyPassword, setVerifyPassword] = React.useState("");
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [type, setType] = React.useState("");
    const [emailRegie, setEmailRegie] = React.useState("");
    const [optionRegie, setOptionRegie] = React.useState([]);
    const [checked, setChecked] = React.useState(false);
    const [messError, setMessError] = React.useState("");
    const [messValid, setMessValid] = React.useState("");

    useEffect(() => {
        const fetchCustomer = async () => {
            setMessError("");
            await axios.get(process.env.REACT_APP_URL_API + 'user/regieandtech/false', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                let regie = [];
                for(const res of response.data){
                    const tmp = {
                        id: res.id,
                        value: res.email,
                        name: res.email
                    }
                    regie.push(tmp);
                }
                setOptionRegie(regie);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
        };

        fetchCustomer();
    }, [tokenHeader]);

    const strUcFirst = (string) => {
        const str = string.toLowerCase();
        return (str+'').charAt(0).toUpperCase()+str.substr(1);
    }

    const strLc = (string) => {
        return string.toLowerCase();
    }

    const handleEmail = (event) => {
        const emailTarget = strLc(event.target.value);
        setEmail(emailTarget);
    }
    
    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleVerifyPassword = (event) => {
        setVerifyPassword(event.target.value);
    }

    const handleFirstname = (event) => {
        const firstnameTarget = strUcFirst(event.target.value);
        setFirstname(firstnameTarget);
    }

    const handleLastname = (event) => {
        const lastnameTarget = strUcFirst(event.target.value);
        setLastname(lastnameTarget);
    }

    const handleType = (event) => {
        setType(event.target.value);
    }

    const handleEmailRegie = (event) => {
        setEmailRegie(event.target.value);
    }

    const selectType = [
        {
            id: 1,
            value: "DEFAULT",
            name: "Choisissez le type de l'utilisateur",
            disabled: true
        },
        {
            id: 2,
            value: "tech",
            name: "Technicien"
        },
        {
            id: 3,
            value: "regie",
            name: "Régie"
        }

    ]

    const handleSubmit = (event) => {
        event.preventDefault();
        setMessError("");
        setMessValid("");
        var btnCreation = document.getElementById("submit");
        btnCreation.disabled = true;
        if(password === verifyPassword){
            if(emailRegie === ""){
                axios.post(process.env.REACT_APP_URL_API + 'user/add', {
                    email,
                    password,
                    lastname,
                    firstname,
                    type
                }, {
                    headers: { 
                        'Authorization': tokenHeader
                    }
                }).then((response) => {
                    setMessError(`L'utilisateur ${response.data.lastname} ${response.data.firstname} a bien été créer. Attendez 5 secondes, vous allez être rediriger.`);
                    setTimeout(() => {
                        window.location = window.location.origin
                    }, 5000);
                }).catch((error) => {
                    if(error.response){
                        if(error.response.status === 401){
                            window.alert("Vous n'êtes pas autorisé à effectuer cette action.");
                            window.location = window.location.origin;
                        }else{
                            setMessError(error.response.data.message);
                        }
                    }
                    btnCreation.disabled = false;
                });
            }else{
                axios.post(process.env.REACT_APP_URL_API + 'user/add', {
                    email,
                    password,
                    lastname,
                    firstname,
                    type,
                    emailRegie
                }, {
                    headers: { 
                        'Authorization': tokenHeader
                    }
                }).then((response) => {
                    setMessError(`L'utilisateur ${response.data.lastname} ${response.data.firstname} a bien été créer. Attendez 5 secondes, vous allez être rediriger.`);
                    setTimeout(() => {
                        window.location = window.location.origin
                    }, 5000);
                }).catch((error) => {
                    if(error.response){
                        if(error.response.status === 401){
                            window.alert("Vous n'êtes pas autorisé à effectuer cette action.");
                            window.location = window.location.origin;
                        }else{
                            setMessError(error.response.data.message);
                        }
                    }
                    btnCreation.disabled = false;
                });
            }
        }else{
            setMessError("Les mots de passe saisie ne sont pas identique.");
            btnCreation.disabled = false;
        }
    }

    const handleBack = () => {
        var history = window.history;
        history.back(); 
    }

    const handleCheckBox = (e) => {
        if(e.target.checked){
            setChecked(true);
        }else{
            setChecked(false);
            setEmailRegie("");
        }
    }

    return(
        <Container>
            <Button id="back" name="Retour" onClick={ handleBack }/>
            <Form onSubmit={handleSubmit}>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                { messValid !== "" ? <Label name={ messValid }/> : <></> }

                <DivGroupInput>
                    <DivInput>
                        <Label name="Email :"/>
                        <Input value={email} id="email" type="email" required={true} name="email" onChange={ handleEmail } />
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Mot de passe :"/>
                        <Input value={password} id="password" type="password" required={true} name="password" onChange={ handlePassword }/>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Saisissez à nouveau le mot de passe :"/>
                        <Input value={verifyPassword} id="verifyPassword" type="password" required={true} name="verifyPassword" onChange={ handleVerifyPassword }/>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Nom :"/>
                        <Input value={lastname} id="lastname" type="text" required={true} name="lastname" onChange={ handleLastname }/>
                    </DivInput>

                    <DivInput>
                        <Label name="Prénom :"/>
                        <Input value={firstname} id="firstname" type="text" required={true} name="firstname" onChange={ handleFirstname }/>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Type :"/>
                        <Select defaultValue={'DEFAULT'} option={ selectType } id="type" required={true} name="type" onChange={ handleType } />
                    </DivInput>
                </DivGroupInput>

                { type === "tech" ? 
                    <DivGroupInput>
                        <DivCheckBox>
                            <Input type="checkbox" id="checkboxRegie" name="checkboxRegie" onChange={ handleCheckBox } />
                            <Label name="Technicien est associé avec une régie"/>
                        </DivCheckBox>
                    </DivGroupInput>
                    :
                    <></>
                }

                { checked ?
                    <DivGroupInputHidden id="divEmailRegie">
                        <DivInput>
                            <Label name="Email de la régie gérant le technicien :"/>
                            <Select value={emailRegie} option={ optionRegie } id="emailRegie" name="emailRegie" required={true} onChange={ handleEmailRegie }/>
                        </DivInput>
                    </DivGroupInputHidden>
                    :
                    <></>
                }

                <Submit>
                    <Input id="submit" type="submit" value="Création" />
                </Submit>
                
            </Form>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 25px;

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 1150px){
        margin-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;

        #back{
            font-size: 15px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: start;

    #messError{
        color: red;
    }
`;

const DivInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2%;
    width: 100%;

    input, select{
        font-size : 18px;
        border: solid 1px black;
        border-radius: 100px;
        padding: 1% 2%;
    }

    label {
        font-size: 25px;
        text-align: justify;
        margin-bottom: 1%;
        font-weight: 700;
    }

    @media screen and (max-width: 750px){
        width: auto;

        input, select, label{
            font-size: 15px !important;
        }
    }
`;

const DivCheckBox = styled(DivInput)`
    flex-direction: row;
    align-items: center;

    label{
        text-align: justify;
        font-size: 18px;
    }
`;

const DivGroupInput = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 750px){
        flex-direction: column;
    }
`;

const Submit = styled.div`
    margin: 5% 0%;
    width: 100%;

    #submit{
        width: 50%;
        font-size: 18px;
        border-radius: 100px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 750px){
        #submit{
            font-size: 15px;
        }
    }
`;

const DivGroupInputHidden = styled(DivGroupInput)`
    display: flex;
`;

export default FormNewCompte;