import React from "react";
import styled from "styled-components";
import { SignatureOrga } from "../../organisms";

const SignatureTemplate = ({ projectId }) => {

    return (
        <Container>
            <SignatureOrga projectId={ projectId }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default SignatureTemplate;