import React from "react";
import styled from "styled-components";
import { BtnCustomer } from "../../molecules";

const CustomerProjectOrga = ({ projectType }) => {

    return(
        <Container>
            <BtnCustomer projectType={ projectType }/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    height: inherit;
`;

export default CustomerProjectOrga;