import React from "react";
import styled from "styled-components";
import { HomeOrga } from "../../organisms";

const HomeTemplate = () => {
    return (
        <Container>
            <HomeOrga/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default HomeTemplate;