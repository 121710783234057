import React from "react";

const Select = (props) => {
    return(
        <>
            <select defaultValue={props.defaultValue} id={props.id} required={props.required} disabled={props.disabled} name={props.name} style={props.style} onChange={props.onChange}>
                {props.option.map((e, index) => {
                    return(
                        <option key={index} id={e.id} value={e.value} disabled={e.disabled} hidden={e.hidden} selected={e.selected} price={e.price} name={e.name}>{e.name}</option>
                    )
                })}
            </select>
        </>
    );
}

export default Select;