import React from "react";
import styled from "styled-components";
import { ComptesOrga } from "../../organisms";

const ComptesTemplate = ({ status }) => {
    return (
        <Container>
            <ComptesOrga status={ status }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default ComptesTemplate;