import React from "react";

const Table = (props) => {
    return(
        <>
            <table id="myTable">
                <thead>
                    <tr>
                        {props.thead.map(e => {
                            return(
                                <th onClick={props.onClick} key={e.id} id={e.id}>{e.name}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {props.tbody.map(t => {
                        return (
                        <tr key={t.id} id={t.id} onClick={t.onClick} className={t.className}>
                            {(() => {
                                return(
                                    <>  
                                        <td><input type="checkbox" id={t.id} name="checkboxProject" onChange={t.onChangeCheckbox}/></td>
                                        <td>{t.name}</td>
                                        <td>{t.address}</td>
                                        <td>{t.cp}</td>
                                        <td>{t.city}</td>
                                        <td>{t.email}</td>
                                        <td>{t.portable}</td>
                                        <td>{t.project}</td>
                                        <td>{t.status}</td>
                                        <td>{t.statusMPR}</td>
                                        <td>{t.statusInstall}</td>
                                        <td id="date">{t.date}</td>
                                    </>
                                )
                            })()}
                        </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    );
}

export default Table;