import React from "react";
import styled from "styled-components";
import { FormAddFileProject } from "../../molecules";

const AddFileProjectOrga = ({ projectId, typeProject }) => {

    return(
        <Container>
            <FormAddFileProject projectId={ projectId } typeProject={ typeProject }/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    height: inherit;
`;

export default AddFileProjectOrga;