import React from "react";
import { Redirect } from "react-router";

const CompteRedirect = () => {
    const cId = window.location.search.split("=")[1];
    return <Redirect to={{
        pathname: "/compte",
        state: {
            compteId: cId
        }
    }}/>
}

export default CompteRedirect;