import React from "react";
import styled from "styled-components";
import { FormNewCompte } from "../../molecules";

const NewCompteOrga = () => {

    return(
        <Container>
            <FormNewCompte/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    height: inherit;
`;

export default NewCompteOrga;