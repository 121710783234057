import './App.css';
import { Home,
  HomeConnex,
  ErrorPage,
  NewCustomer,
  NewProject,
  Comptes,
  NewCompte,
  Compte,
  Projects,
  CustomerProject,
  ChoiceCustomer,
  Project,
  Signature,
  EndSignatureProject,
  AddFileAfter,
  StatusMPR,
  ProjectsRedirect,
  ProjectRedirect,
  ComptesRedirect,
  NewProjectRedirect,
  SignatureRedirect,
  EndSignatureProjectRedirect,
  AddFileAfterRedirect,
  CompteRedirect,
  StatusMPRRedirect,
  AddFileProject,
  AddFileProjectRedirect,
  ProjectsRedirectStatus,
  NewFacture,
  NewFactureRedirect,
  CustomerRedirect,
  Customer
} from './pages';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import React, { useEffect } from "react";
import { Button } from './components/atoms';

function App() {

  const storedValueAsTokenHeader = localStorage.getItem("tokenHeader");
  const [tokenHeader, setTokenHeader] = React.useState(
    storedValueAsTokenHeader ? storedValueAsTokenHeader : ""
  )

  useEffect(() => {
    localStorage.setItem("tokenHeader", tokenHeader);
  }, [tokenHeader]);

  window.close = function (e) {
    var storage = window.localStorage;
    storage.clear()
  }

  const handleBack = () => {
    window.location = window.location.origin;
  }

  const styleBack = {
    width: "15%",
    alignSelf: "center",
    fontSize: "15px",
    color: "white",
    textTransform: "uppercase",
    border: "solid 1px black",
    borderRadius: "100px",
    backgroundImage: "linear-gradient(to bottom right, #FBFE00, #0133FE)",
    boxShadow: "10px 4px 8px black",
    marginTop: "1%"
  }

  return (
    <div className="App">
      {tokenHeader !== "" ?
        <Button id="back" name="Accueil" onClick={ handleBack } style={styleBack}/>
        :
        <></>
      }
      <Router>
        <Switch>
          <Route path="/" exact>
            { tokenHeader !== "" ? <Home/> : <Redirect to="/connect"/> }
          </Route>

          <Route path="/connect" exact>
            <HomeConnex setTokenHeader={ setTokenHeader }/>
          </Route>

          <Route path="/404" exact>
            <ErrorPage name="Erreur 404 not found"/>
          </Route>

          <Route path="/project" exact component={Project}/>

          <Route path="/projectRedirect" exact>
            <ProjectRedirect/>
          </Route>

          <Route path="/projects" exact component={Projects}/>

          <Route path="/projectsRedirect" exact>
            <ProjectsRedirect/>
          </Route>

          <Route path="/projectsRedirectStatus" exact>
            <ProjectsRedirectStatus/>
          </Route>

          <Route path="/finishProjects" exact>
            <ComptesRedirect/>
          </Route>

          <Route path="/customerProject" exact component={CustomerProject}/>
          
          <Route path="/choiceCustomer" exact>
            <ChoiceCustomer/>
          </Route>

          <Route path="/newCustomer" exact>
            <NewCustomer/>
          </Route>

          <Route path="/customer" exact component={Customer}/>

          <Route path="/customerRedirect" exact>
            <CustomerRedirect/>
          </Route>

          <Route path="/newProject" exact component={NewProject}/>

          <Route path="/newProjectRedirect" exact>
            <NewProjectRedirect/>
          </Route>

          <Route path="/newFacture" exact component={NewFacture}/>

          <Route path="/newFactureRedirect" exact>
            <NewFactureRedirect/>
          </Route>

          <Route path="/signature" exact component={Signature}/>

          <Route path="/signatureRedirect" exact>
            <SignatureRedirect/>
          </Route>

          <Route path="/endSignatureProject" exact component={EndSignatureProject}/>

          <Route path="/endSignatureProjectRedirect" exact>
            <EndSignatureProjectRedirect/>
          </Route>

          <Route path="/addFileAfter" exact component={AddFileAfter}/>

          <Route path="/addFileAfterRedirect" exact>
            <AddFileAfterRedirect/>
          </Route>

          <Route path="/addFileProject" exact component={AddFileProject}/>

          <Route path="/addFileProjectRedirect" exact>
            <AddFileProjectRedirect/>
          </Route>

          <Route path="/comptes" exact component={Comptes}/>

          <Route path="/newCompte" exact>
            <NewCompte/>
          </Route>

          <Route path="/compte" exact component={Compte}/>

          <Route path="/compteRedirect" exact>
            <CompteRedirect/>
          </Route>

          <Route path="/statusMPR" exact component={StatusMPR}/>

          <Route path="/statusMPRRedirect" exact>
            <StatusMPRRedirect/>
          </Route>

          <Route path="*">
            <Redirect to="/404"/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;