import React from "react";
import { Redirect } from "react-router";

const AddFileProjectRedirect = () => {
    const v = window.location.search.split("?")[1];
    const tmp = v.split("&");
    const pId = tmp[0].split("=")[1];
    const type = tmp[1].split("=")[1];
    return <Redirect to={{
        pathname: "/addFileProject",
        state: {
            projectId: pId,
            typeProject: type
        }
    }}/>
}

export default AddFileProjectRedirect;