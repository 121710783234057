import React from "react";
import styled from "styled-components";
import { NewProjectOrga } from "../../organisms";

const NewProjectTemplate = ({ customerId, projectId }) => {

    return (
        <Container>
            <NewProjectOrga customerId={ customerId } projectId={ projectId }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default NewProjectTemplate;