import React from "react";
import { Redirect } from "react-router";
import { HomeTemplate } from "../../components/templates";

const Home = () => {
    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/connect" />
    }else{
        return (
            <>
                <HomeTemplate/>
            </>
        )
    }
}

export default Home;