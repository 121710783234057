import React, { useEffect } from "react";
import { Button, Input, Label} from "../../atoms";
import styled from "styled-components";
import ImgVol from "../../../image/fichier_volumineux.png";
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';

const FormAddFileAfter = ({ projectId }) => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [files, setFiles] = React.useState([]);
    const [messError, setMessError] = React.useState("");

    useEffect(() => {
        const fetchCustomer = () => {
            setMessError("");
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                if(response.data.type !== "tech"){
                    window.alert("Vous n'êtes pas autorisé à effectuer cette action");
                    window.location = window.location.origin + "/404";
                }
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
        };

        fetchCustomer();
    }, [tokenHeader, projectId]);

    
    const handleSubmit = (event) => {
        event.preventDefault();
        setMessError("");
        const buttonSubmit = document.getElementById("submit");
        buttonSubmit.disabled = true;
        axios.post(process.env.REACT_APP_URL_API + 'project/addFileAfter', {
            id: projectId,
            files: files
        }, {
            headers : {
                'Authorization': tokenHeader
            }
        }).then((response) => {
            setTimeout(() => {
                window.location = window.location.origin;
            }, 1000);
        }).catch((error) => {
            if(error.response){
                if(error.response.status === 401){
                    var storage = window.localStorage;
                    storage.clear();
                    window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                    window.location = window.location.origin;
                }else{
                    setMessError(error.response.data.message);
                }
            }
            buttonSubmit.disabled = false;
        });
    }

    const handleBack = () => {
        var history = window.history;
        history.back();
    }

    const handleFiles = (event) => {
        const userFiles = event.target.files;
        let divFiles = document.getElementById("divFiles");
        let fileByteArray = files;
        setMessError("");
        if(userFiles){
            for(const file of userFiles){
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async () => {
                    let fileNotAccept = false;
                    let exist = event.target.attributes["nb"].value;
                    let filename = file.name.split(".");
                    let type = filename[filename.length - 1];
                    filename = event.target.id;
                    if(exist !== 0){
                        filename = filename + "_" + exist;
                    }
                    exist++;
                    event.target.attributes["nb"].value = exist;
                    let fil = {};
                    let tmp = reader.result.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                    let typeTmp = tmp.split("/")[1];
                    let data = tmp.split("/")[0];
                    if(data === "image"){
                        const divFile = document.createElement("div");
                        divFile.id = "divFile";
                        const img = document.createElement('img');
                        img.setAttribute("src", reader.result);
                        img.setAttribute("style", "width: 100%");
                        const imgTitle = document.createElement("label");
                        imgTitle.innerText = filename;
                        const deleteImg = document.createElement("a");
                        deleteImg.href = "#";
                        deleteImg.innerText = "X";
                        deleteImg.id = filename;
                        deleteImg.addEventListener("click", handleDeleteFile);
                        divFile.appendChild(img);
                        divFile.appendChild(imgTitle);
                        divFile.appendChild(deleteImg);
                        divFiles.appendChild(divFile);
                    }else if(data === "application"){
                        if(typeTmp === "pdf"){
                            const divFile = document.createElement("div");
                            divFile.id = "divFile";
                            const iframe = document.createElement('iframe');
                            let result = reader.result;
                            if(reader.result.length > 500000){
                                result = ImgVol;
                            }
                            iframe.setAttribute("src", result);
                            iframe.setAttribute("width", "200");
                            iframe.setAttribute("title", filename);
                            const imgTitle = document.createElement("label");
                            imgTitle.innerText = filename;
                            const deleteImg = document.createElement("a");
                            deleteImg.href = "#";
                            deleteImg.innerText = "X";
                            deleteImg.id = filename;
                            deleteImg.addEventListener("click", handleDeleteFile);
                            divFile.appendChild(iframe);
                            divFile.appendChild(imgTitle);
                            divFile.appendChild(deleteImg);
                            divFiles.appendChild(divFile);
                        }else{
                            fileNotAccept = true;
                        }
                    }else{
                        fileNotAccept = true;
                    }
                    if(!fileNotAccept){
                        fil.title = filename;
                        if(data === "image"){
                            const pdf = await PDFDocument.create();
                            let image;
                            if(typeTmp === "png"){
                                image = await pdf.embedPng(reader.result);
                            }else if(typeTmp === "jpeg" || typeTmp === "jpg"){
                                image = await pdf.embedJpg(reader.result);
                            }else{
                                image = undefined;
                            }
                            if(image){
                                const pageImg = pdf.addPage();
                                const ScaleImg = image.scale(1);
                                let width = ScaleImg.width > pageImg.getWidth() ? ScaleImg.width / 2 : ScaleImg.width;
                                let height = ScaleImg.height > pageImg.getHeight() ? ScaleImg.height / 2 : ScaleImg.height;
                                while(width > pageImg.getWidth()){
                                    width = width / 1.5;
                                    height = height / 1.5;
                                }
                                while(height > pageImg.getHeight()){
                                    width = width / 1.5;
                                    height = height / 1.5;
                                }
                                pageImg.drawImage(image, {
                                    x: 10,
                                    y: pageImg.getHeight() - height - 10,
                                    width: width,
                                    height: height,
                                });
                                const U8A = await pdf.saveAsBase64();
                                fil.result = U8A;
                                fil.type = "pdf";
                                fileByteArray.push(fil);
                            }else{
                                window.scrollTo(0,0);
                                setMessError("Le fichier que vous essayez d'ajouter n'est pas accepté.");
                            }
                        }else{
                            fil.result = reader.result;
                            fil.type = type;
                            fileByteArray.push(fil);
                        }
                    }else{
                        setMessError("Le fichier que vous essayez d'ajouter n'est pas accepté.");
                    }
                }
                reader.onerror = (error) => {
                    setMessError(error);
                };                
            }
        }
        setFiles(fileByteArray);
    }

    const handleDeleteFile = (e) => {
        e.preventDefault();
        const name = e.target.id;
        const divFile = e.target.parentNode;
        const parentNode = divFile.parentNode;
        let fileByteArray = files;
        for(let i = 0; i < fileByteArray.length; i++){
            if(fileByteArray[i].title === name){
                fileByteArray.splice(i, 1);
            }
        }
        parentNode.removeChild(divFile);
        setFiles(fileByteArray);
    }

    return(
        <Container>
            <Button id="back" name="Retour" onClick={ handleBack }/>
            <Form onSubmit={handleSubmit}>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                
                <DivGroupInput>
                    <DivInput>
                        <Label name="Fichier à ajouter :"/>
                        <input nb="0" id="fichier_ajouter" type="file" required={true} name="files" onChange={ handleFiles } accept="image/*, .pdf"/>
                        <div id="divFiles"></div>
                    </DivInput>
                </DivGroupInput>

                <Submit>
                    <Input id="submit" type="submit" value="Valider"/>
                </Submit>
                
            </Form>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 25px;

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 1350px){
        margin-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;

        #back{
            font-size: 15px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: start;

    label{
        text-align: justify;
        margin-bottom: 1%;
        margin-top: 1%;
        font-size: 22px;
        font-weight: 700;
    }

    #messError{
        color: red;
    }

    #divPDF{
        height: 350px;
    }
`;

const DivInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2%;
    width: 100%;

    @media screen and (max-width: 750px){
        width: auto;
        height: inherit;
    }

    canvas{
        border: 2px solid black;
        background: white;
    }

    input{
        border-radius: 5%;
        font-size : 18px;
    }

    #divFiles{
        display: flex;
        margin-top: 2%;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    #divFile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 29%;
        padding: 1%;
        margin: 1%;
        position: relative;

        label{
            font-size: 20px;
            font-weight: 100;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
            text-align: center;
            position: absolute;
            bottom: 2%
        }

        a{
            position: absolute;
            top: 2%;
            left: 5%;
            font-weight: 700;
            color: red;
            font-size: 20px;
        }
    }

    #deleteCanva{
        margin-top: 1%;
        width: 25%;
        align-self: end;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
        box-shadow: 10px 4px 8px black;
    }

    iframe{
        background: white;
        border: 0px;
    }
`;

const DivGroupInput = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 750px){
        flex-direction: column;
    }
`;

const Submit = styled.div`
    margin: 5% 0%;
    width: 100%;

    #submit{
        width: 50%;
        font-size: 18px;
        border-radius: 100px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 750px){
        #submit{
            font-size: 15px;
        }
    }
`;

export default FormAddFileAfter;