import React from "react";
import { Input, Label } from "../../atoms";
import styled from "styled-components";
import axios from 'axios';

const FromConnex = ({ setTokenHeader }) => {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [messError, setMessError] = React.useState("");

    const strLc = (string) => {
        return string.toLowerCase()
    }

    const handleEmailChange = (event) => {
        const emailTarget = strLc(event.target.value) 
        setEmail(emailTarget);
    }
    
    const handlePasswordChange = (event) => { 
        setPassword(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setMessError("");
        axios.post(process.env.REACT_APP_URL_API + 'user/auth', {
            email,
            password
        }).then((response) => {
            setTokenHeader(`Bearer ${response.data.access_token}`);
            window.location = window.location.origin;
        }).catch((error) => {
            if(error.response){
                setMessError(error.response.data.message);
            }
        });
    }

    return(
        <Container>
            <form onSubmit={handleSubmit}>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }

                <Login>
                    <Label name="Email utilisateur :"/>
                    <Input value={email} id="email" type="email" required={true} name="login" onChange={ handleEmailChange } />
                </Login>

                <Password>
                    <Label name="Mot de passe :"/>
                    <Input value={password} id="password" type="password" required={true} name="password" onChange={ handlePasswordChange }/>
                </Password>

                <Submit>
                    <Input type="submit" value="Connexion" />
                </Submit>
            </form>
        </Container>
    );
}

const Container = styled.div`
    padding: 5%;

    label{
        text-align: justify;
        margin-bottom: 3%;
        font-size: 18px;
        color: black;
    }

    #messError{
        font-size: 22px;
        color: red;
    }

    input{
        height: 35px;
        border-radius: 5%;
        font-size : 18px;
        background: white;
        border-color: black;
        color: black;
    }
`;

const Login = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10%;
`;

const Password = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10%;
`;

const Submit = styled.div`
    margin: 10%;

    input{
        width: 85%;
        font-size: 20px;
        border-radius: 100px;
        border: 1px solid black;
        font-family: initial;
        color: black;
        text-transform: uppercase;
        background: white;
    }
`;

export default FromConnex;