import React from "react";
import { Redirect } from "react-router";
import { CompteTemplate } from "../../components/templates";

const Compte = props => {
    const { compteId } = props.location.state;
    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404" />
    }else{
        return (
            <>
                <CompteTemplate compteId={ compteId }/>
            </>
        )
    }
}

export default Compte;