import { useCallback, useEffect, useState } from "react";
import { Button, Input, Label, Select } from "../../atoms";
import styled from "styled-components";
import axios from 'axios';

const FormNewProject = ({ customerId, projectId }) => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [name, setName] = useState("");
    const [typeProject, setTypeProject] = useState("");
    const [dateDevis, setDateDevis] = useState("");
    const [delegate, setDelegate] = useState("");
    const [marqueExist, setMarqueExist] = useState("");
    const [modeleExist, setModeleExist] = useState("");
    const [typeAppoint, setTypeAppoint] = useState("");
    const [numEtude, setNumEtude] = useState("");
    const [numParcelle, setNumParcelle] = useState("");
    const [tensionLog, setTensionLog] = useState("-1");
    const [typeEmetteur, setTypeEmetteur] = useState("");
    const [regimeEau, setRegimeEau] = useState("");
    const [marque, setMarque] = useState("");
    const [modele, setModele] = useState("");
    const [puissance, setPuissance] = useState("");
    const [statutMenage, setStatutMenage] = useState("");
    const [prixProjet, setPrixProjet] = useState("0");
    const [numDossier, setNumDossier] = useState("MPR-");
    const [checkPrimeRenov, setCheckPrimeRenov] = useState(false);
    const [prixTotal, setPrixTotal] = useState("0");
    const [variable, setVariable] = useState({});
    const [optionTension, setOptionTension] = useState([]);
    const [optionTypeEmetteur, setOptionTypeEmetteur] = useState([]);
    const [optionRegimeEau] = useState([
        {
            id: 0,
            value: "",
            name: "Choisissez un regime d'eau",
            disabled: true,
            hidden: true
        },
        {
            id: 1,
            value: "BT",
            name: "Basse température",
            disabled: true,
            hidden: true
        },
        {
            id: 2,
            value: "MT",
            name: "Moyen température",
            disabled: true,
            hidden: true
        },
        {
            id: 3,
            value: "HT",
            name: "Haute température",
            disabled: true,
            hidden: true
        }
    ]);
    const [optionMarque, setOptionMarque] = useState([{
        id: 0,
        value: "",
        name: "Choisissez une marque",
        disabled: true,
        hidden: true
    }]);
    const [optionModele, setOptionModele] = useState([{
        id: 0,
        value: "",
        name: "Choisissez un modèle",
        disabled: true,
        hidden: true
    }]);
    const [optionPuissace, setOptionPuissance] = useState([{
        id: 0,
        value: "",
        name: "Choisissez une puissance",
        disabled: true,
        hidden: true
    }]);
    const [numVar, setNumVar] = useState(0);
    const [maxVar, setMaxVar] = useState(0);
    const [minVar, setMinVar] = useState(0);
    const [optionVar, setOptionVar] = useState([{}]);
    const [numAccessoires, setNumAccessoires] = useState(0);
    const [optionAccessoires, setOptionAccessoires] = useState([]);
    const [optionForfait, setOptionForfait] = useState([]);
    const [messError, setMessError] = useState("");
    const [messValid, setMessValid] = useState("");
    const [checkPrimeCoupPouce, setCheckPrimeCoupPouce] = useState(false);
    const [object, setObject] = useState("");

    const getMarque = useCallback(async (type) => {
        setMessError("");
        await axios.get(process.env.REACT_APP_URL_API + `project/marques/${type}`,{
            headers : {
                'Authorization': tokenHeader
            }
        }).then((response) => {
            let marque = [
                {
                    id: 0,
                    value: "",
                    name: "Choisissez une marque",
                    disabled: true,
                    hidden: true
                }
            ];
            for(const resp of response.data.marque){
                const id = resp.id;
                const name = resp.nomMarque;
                const value = resp.id;
                const tmp = {
                    id: id,
                    value: value,
                    name: name
                }
                marque.push(tmp);
            }
            setOptionMarque(marque);
            const varia = response.data.variable;
            setNumVar(varia.num);
            setMaxVar(varia.max);
            setMinVar(varia.min);
            let options = [
                {
                    id: 0,
                    value: "",
                    name: "Choisissez une variable",
                    disabled: true,
                    hidden: true
                },
                ...varia.options
            ];
            setOptionVar(options);
            if(varia.optionForfait){
                options = [
                    {
                        id: 0,
                        value: "",
                        name: "Choisissez un accessoire",
                        disabled: true,
                        hidden: true
                    },
                    ...varia.optionAccessoires
                ];
                setOptionAccessoires(options);
                options = [
                    {
                        id: 0,
                        value: "",
                        name: "Choisissez une variable",
                        disabled: true,
                        hidden: true
                    },
                    ...varia.optionForfait
                ];
                setOptionForfait(options);
            }
            if(varia.optionTension){
                options = [
                    {
                        id: 0,
                        value: "-1",
                        name: "Choisissez une tension",
                        disabled: true,
                        hidden: true,
                    },
                    ...varia.optionTension
                ];
                setOptionTension(options);
                options = [
                    {
                        id: 0,
                        value: "",
                        name: "Choisissez un type d'émetteur",
                        disabled: true,
                        hidden: true
                    },
                    ...varia.optionTypeEmetteur
                ];
                setOptionTypeEmetteur(options);
            }
        }).catch((error) => {
            window.scrollTo(0,0);
            setMessError(error.response.data.message);
        })
    }, [tokenHeader]);

    const getModele = useCallback(async (type, marque, tension) => {
        setMessError("");
        await axios.get(process.env.REACT_APP_URL_API + `project/models/${type}/${marque}/${tension}`,{
            headers : {
                'Authorization': tokenHeader
            }
        }).then((response) => {
            let modele = [
                {
                    id: 0,
                    value: "",
                    name: "Choisissez un modèle",
                    disabled: true,
                    hidden: true
                }
            ];
            for(const resp of response.data){
                const id = resp.id;
                const name = resp.nomModele;
                const value = resp.id;
                const tmp = {
                    id: id,
                    value: value,
                    name: name
                }
                modele.push(tmp);
            }
            setOptionModele(modele);
        }).catch((error) => {
            window.scrollTo(0,0);
            setMessError(error.response.data.message);
        })
    }, [tokenHeader])

    const getPuissance = useCallback(async (type, modele, tension) => {
        setMessError("");
        await axios.get(process.env.REACT_APP_URL_API + `project/modelTension/${type}/${modele}/${tension}`, {
            headers : {
                'Authorization': tokenHeader
            }
        }).then((response) => {
            let opPuissance = [
                {
                    id: 0,
                    value: "",
                    name: "Choisissez une puissance",
                    disabled: true,
                    hidden: true
                }
            ]
            for(const resp of response.data){
                const id = resp.id;
                const name = resp.puissanceKw + " kW";
                const value = resp.id;
                const tmp = {
                    id: id,
                    value: value,
                    name: name
                }
                opPuissance.push(tmp);
            }
            setOptionPuissance(opPuissance);
        }).catch((error) => {
            window.scrollTo(0,0);
            setMessError(error.response.data.message);
        })
    }, [tokenHeader])

    const getPrixProject = useCallback(async (type, modele) => {
        setMessError("");
        await axios.get(process.env.REACT_APP_URL_API + `project/model/${type}/${modele}`, {
            headers : {
                'Authorization': tokenHeader
            }
        }).then((response) => {
            setPrixProjet(response.data.prix);
            const inputPrixTotal = document.getElementById("price_total");
            inputPrixTotal.addEventListener("change", handlePrixTotal);
            var evt = new Event('change', { "bubbles": true });
            inputPrixTotal.dispatchEvent(evt);
        }).catch((error) => {
            window.scrollTo(0,0);
            setMessError(error.response.data.message);
        })
    }, [tokenHeader])

    useEffect(() => {
        const fetchCustomer = () => {
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                if(response.data.type !== "tech"){
                    window.alert("Vous n'êtes pas autorisé à effectuer cette action");
                    window.location = window.location.origin + "/404";
                }
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        window.scrollTo(0,0);
                        setMessError(error.response.data.message);
                    }
                }
            });
            if(projectId !== "0"){
                axios.get(process.env.REACT_APP_URL_API + `project/${projectId}`, {
                    headers : {
                        'Authorization': tokenHeader
                    }
                }).then(async (response) => {
                    const complement = JSON.parse(response.data.complement);
                    const varia = complement.var;
                    setVariable(varia);
                    setName(complement.name);

                    var selectType = document.getElementById("name");
                    selectType.value = complement.typeProject;
                    setTypeProject(complement.typeProject);
                    await getMarque(complement.typeProject);

                    if(complement.dateDevis){
                        let parseDate = Date.parse(complement.dateDevis);
                        let dateCreate = new Date(parseDate);
                        setDateDevis(`${dateCreate.toISOString().split('T')[0]}`);
                    }

                    if(complement.object){
                        var selectObject = document.getElementById("object");
                        selectObject.value = complement.object;
                        setObject(complement.object);
                    }

                    if(complement.delegate){
                        var selectDelegate = document.getElementById("delegate");
                        selectDelegate.value = complement.delegate;
                        setDelegate(complement.delegate);
                    }

                    if(complement.marqueExist){
                        setMarqueExist(complement.marqueExist);
                        setModeleExist(complement.modeleExist);
                        if(complement.typeProject === "ssc"){
                            var selectTypeAppoint = document.getElementById("typeAppoint");
                            selectTypeAppoint.value = complement.typeAppoint;
                            setTypeAppoint(complement.typeAppoint);
                        }
                    }

                    setNumEtude(complement.numEtude);
                    setNumParcelle(complement.numParcelle);
                    setNumDossier(complement.num_dossier);

                    if(complement.checkPrimeRenov){
                        var checkboxPrimeRenov = document.getElementById("checkboxPrimeRenov");
                        checkboxPrimeRenov.checked = true;
                        setCheckPrimeRenov(true);
                    }

                    if(complement.statutMenage){
                        var selectStatutMenage = document.getElementById("statutMenage");
                        selectStatutMenage.value = complement.statutMenage;
                        setStatutMenage(complement.statutMenage);
                    }

                    let tension;
                    if(complement.tensionLog){
                        var selectTension = document.getElementById("tension");
                        var selectTypeEmetteur = document.getElementById("typeEmetteur");
                        var selectRegimeEau = document.getElementById("regimeEau");
                        setTensionLog(complement.tensionLog);
                        setTypeEmetteur(complement.typeEmetteur);
                        setRegimeEau(complement.regimeEau);
                        if(selectTension){
                            selectTension.value = complement.tensionLog;
                            selectTypeEmetteur.value = complement.typeEmetteur;
                            selectRegimeEau.value = complement.regimeEau;
                        }
                        tension = complement.tensionLog;
                    }else{
                        tension = "-1";
                    }

                    setTimeout(async () => {
                        if(complement.marque){
                            var selectMarque = document.getElementById("marque");
                            selectMarque.value = complement.marque;
                            setMarque(complement.marque);
                            await getModele(complement.typeProject, complement.marque, tension);

                            setTimeout(async () => {
                                var selectModele = document.getElementById("modele");
                                selectModele.value = complement.modele;
                                setModele(complement.modele);
                                if(["pac", "ch", "chg"].includes(complement.typeProject)){
                                    await getPuissance(complement.typeProject, complement.modele, tension);

                                    setTimeout(() => {
                                        var selectPuissance = document.getElementById("puissance");
                                        selectPuissance.value = complement.puissance;
                                        setPuissance(complement.puissance);
                                    }, 2);
                                }
                                if(complement.typeProject === "ssc"){
                                    if(complement.checkPrimeCoupPouce){
                                        var checkboxPrimeCoupPouce = document.getElementById("checkboxPrimeCoupPouce");
                                        checkboxPrimeCoupPouce.checked = true;
                                        setCheckPrimeCoupPouce(true);
                                    }
                                }
                            }, 1);
                        }

                        setPrixProjet(complement.prixProjet);
                        setPrixTotal(complement.prixTotal);

                        let numV = 0;
                        let numAcc = 0;
                        Object.keys(varia).forEach((key) => {
                            var acces = new RegExp("varA");
                            var forf = new RegExp("varF");
                            if(key.match(acces)){
                                numAcc++;
                            }else if(key.match(forf)){
                            }else{
                                numV++;
                            }
                            setTimeout(() => {
                                var select = document.getElementById(key);
                                select.value = varia[key].value;
                                var evt = new Event('change', { "bubbles": true });
                                select.dispatchEvent(evt);
                                var input = document.getElementById("price_" + key);
                                input.value = varia[key].price;
                                setTimeout(() => {
                                    if(varia[key].amount > 1){
                                        var input = document.getElementById("amount_" + key);
                                        input.value = varia[key].amount;
                                    }
                                }, 10);
                            }, 1);
                        });
                        setNumVar(numV);
                        setNumAccessoires(numAcc);
                    }, 1);
                }).catch((error) => {
                    if(error.response){
                        if(error.response.status === 401){
                            var storage = window.localStorage;
                            storage.clear();
                            window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                            window.location = window.location.origin;
                        }else{
                            window.scrollTo(0,0);
                            setMessError(error.response.data.message);
                        }
                    }
                });
            }
        };

        fetchCustomer();
    }, [tokenHeader, projectId, getMarque, getModele, getPuissance, getPrixProject]);

    const handleName = (event) => {
        let namePro = "";
        let proConf = true;
        setMessError("");
        
        setMarque("");
        setMarqueExist("");

        var selectMarque = document.getElementById("marque");
        selectMarque.value = "";

        setModele("");
        setModeleExist("");

        var selectModele = document.getElementById("modele");
        selectModele.value = "";

        setTensionLog("-1");
        setOptionTension([]);

        setTypeEmetteur("");

        setRegimeEau("");
        let optionRE = optionRegimeEau;
        Object.keys(optionRE).forEach(key => optionRE[key].hidden = true);

        setPuissance("");
        let varia = variable;
        Object.keys(varia).forEach(key => delete varia[key]);
        setVariable(varia);

        setNumVar(0);
        setNumAccessoires(0);
        setOptionAccessoires([]);
        setMaxVar(0);
        setMinVar(0);

        setOptionModele([{
            id: 0,
            value: "",
            name: "Choisissez un modèle",
            disabled: true,
            hidden: true
        }]);

        for(const pro of optionType){
            if(pro.value === event.target.value){
                proConf = true;
                namePro = pro.name;
                setTypeProject(event.target.value);
                getMarque(event.target.value);
                break;
            }else{
                proConf = false;
            }
        }
        if(!proConf){
            window.scrollTo(0,0);
            setMessError("Le type de projet n'est pas conforme.");
            return true;
        }
        const nameTarget = namePro;
        setName(nameTarget);
    }

    const handleDateDevis = (event) => {
        event.preventDefault();
        const numDate = event.target.valueAsNumber;
        let dateCreate = new Date(numDate);
        setDateDevis(`${dateCreate.toISOString().split('T')[0]}`);
    }

    const handleObject = (event) => {
        const value = event.target.value;
        setObject(value);
    }

    const handleDelegate = (event) => {
        event.preventDefault();
        setNumEtude("");
        setNumParcelle("");
        const value = event.target.value;
        setDelegate(value);
    }

    const handleTension = (event) => {
        setTensionLog(event.target.value);
    }

    const handleTypeEmetteur = (event) => {
        let value = event.target.value;
        setRegimeEau("");
        const selectRegime = document.getElementById("regimeEau");
        selectRegime.value = "";
        let optionRE = optionRegimeEau;
        Object.keys(optionRE).forEach(key => optionRE[key].hidden = true);
        switch(value){
            case "plancher":
                Object.keys(optionRE).forEach(key => {
                    if(optionRE[key].value === "BT"){
                        optionRE[key].hidden = false;
                        optionRE[key].disabled = false;
                    }
                })
                break;
            case "radiaBT":
                Object.keys(optionRE).forEach(key => {
                    if(optionRE[key].value === "BT"){
                        optionRE[key].hidden = false;
                        optionRE[key].disabled = false;
                    }
                })
                break;
            case "radiaAcier":
                Object.keys(optionRE).forEach(key => {
                    if(optionRE[key].value === "MT" || optionRE[key].value === "HT"){
                        optionRE[key].hidden = false;
                        optionRE[key].disabled = false;
                    }
                })
                break;
            case "radiaFonte":
                Object.keys(optionRE).forEach(key => {
                    if(optionRE[key].value === "MT" || optionRE[key].value === "HT"){
                        optionRE[key].hidden = false;
                        optionRE[key].disabled = false;
                    }
                })
                break;
            case "combine":
                Object.keys(optionRE).forEach(key => {
                    if(optionRE[key].value === "BT"){
                        optionRE[key].hidden = false;
                        optionRE[key].disabled = false;
                    }
                })
                break;
            default:
                break;
        }
        setTypeEmetteur(value);
    }

    const handleRegimeEau = (event) => {
        setRegimeEau(event.target.value);
    }

    const handleMarqueExist = (event) => {
        setMarqueExist(event.target.value);
    }

    const handleModeleExist = (event) => {
        setModeleExist(event.target.value);
    }

    const handleTypeAppoint = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setTypeAppoint(value);
    }

    const handleMarque = (event) => {
        const selectModele = document.getElementById("modele");
        setMessError("");
        selectModele.value = "";
        setModele("");
        setMarque(event.target.value);
        if(typeProject === "pac"){
            if(tensionLog === "-1"){
                window.scrollTo(0,0);
                setMessError("Veuillez choisir une tension du logement.");
                const selectMarque = document.getElementById('marque');
                selectMarque.value = "";
                return true;
            }
        }
        getModele(typeProject, event.target.value, tensionLog);
    }

    const handleModele = (event) => {
        setModele(event.target.value);
        if(typeProject !== "cesol"){
            getPrixProject(typeProject, event.target.value);
        }
        if(['pac', 'ch', 'chg'].includes(typeProject)){
            getPuissance(typeProject, event.target.value, tensionLog);
        }
    }

    const handleNumEtude = (event) => {
        setNumEtude(event.target.value);
    }
    
    const handleNumParcelle = (event) => {
        setNumParcelle(event.target.value);
    }

    const handlePuissance = (event) => {
        setPuissance(event.target.value);
    }

    const handlePrixProjet = (event) => {
        const inputPrixTotal = document.getElementById("price_total");
        inputPrixTotal.addEventListener("change", handlePrixTotal);
        var evt = new Event('change', { "bubbles": true });
        inputPrixTotal.dispatchEvent(evt);
        setPrixProjet(event.target.value);
    }

    const handleNumDossier = (event) => {
        setNumDossier(event.target.value);
    }

    const handleCheckPrimeRenov = (event) => {
        if(event.target.checked){
            setCheckPrimeRenov(true);
        }else{
            setCheckPrimeRenov(false);
        }
    }

    const handleCheckPrimeCoupPouce = (event) => {
        if(event.target.checked){
            setCheckPrimeCoupPouce(true);
        }else{
            setCheckPrimeCoupPouce(false);
        }
    }

    const handleVar = (event) => {
        setMessError("");
        let exist = false;
        let amount = 1;
        const id = event.target.id;
        const namevaria = event.target.name;
        const name = event.target.selectedOptions[0].attributes["name"].value;
        const idOption = event.target.selectedOptions[0].attributes["id"].value;
        let div = event.target.parentNode.parentNode;
        let divAmount = div.lastChild;
        if(divAmount.id === "amountVar"){
            div.removeChild(divAmount);
        }
        let value = event.target.value;
        const price = event.target.selectedOptions[0].attributes["price"].value;
        let varia = variable;
        let nameExist;
        Object.keys(varia).forEach(key =>{
            if(id !== key){
                var tmp = varia[key].idOption.split("_")[0];
                var idOptionSplit = idOption.split("_")[0];
                if(tmp === idOptionSplit){
                    exist = true;
                    nameExist = varia[key].var;
                }
            }
            if(id === key){
                amount = varia[key].amount;
            }
        })
        if(idOption.split("_")[0] === "module" || idOption === "micro_onduleur"){
            let amountDiv = document.createElement("div");
            amountDiv.id = "amountVar";
            amountDiv.style = "padding: 0% 0% 0% 1%; display: flex; flex-direction: column; width: 25%; margin: 2% 0%;";
            let amountLabel = document.createElement("label");
            amountLabel.innerText = "Nombre :";
            let amountInput = document.createElement("input");
            amountInput.id = "amount_"+id;
            amountInput.type = "text";
            amountInput.required = true;
            amountInput.value = amount;
            amountInput.addEventListener("change", handleAmount);
            amountInput.style = "border-radius: 5%; font-size: 18px;";
            amountDiv.appendChild(amountLabel);
            amountDiv.appendChild(amountInput);
            div.appendChild(amountDiv);
            event.target.style = "width: 100%";
        }else{
            amount = 1;
        }
        if(exist){
            window.scrollTo(0,0);
            value = "";
            setMessError(`Cette variable fait partie d'un groupe de variables dans laquel la ${nameExist} en fait partie.`);
        }else{
            const inputPrice = event.target.parentNode.nextElementSibling.lastChild;
            if(idOption.split("_")[0] === "module" || idOption === "micro_onduleur"){
                event.target.parentNode.nextElementSibling.style = "width: 25%";
            }else{
                event.target.parentNode.nextElementSibling.style = "";
            }
            inputPrice.addEventListener("change", handlePrice);
            inputPrice.value = price;
            var evt = new Event('change', { "bubbles": true });
            inputPrice.dispatchEvent(evt);
            varia[id] = {
                value,
                idOption,
                var: namevaria,
                name,
                price,
                amount
            };
            setVariable(varia);
        }
    }

    console.log(variable);

    const handlePrice = (event) => {
        const id = event.target.id.split("_")[1];
        const value = event.target.value;
        const inputPrixTotal = document.getElementById("price_total");
        inputPrixTotal.addEventListener("change", handlePrixTotal);
        var evt = new Event('change', { "bubbles": true });
        inputPrixTotal.dispatchEvent(evt);
        let varia = variable;
        Object.keys(varia).forEach(key =>{
            if(id === key){
                varia[key].price = value;
            }
        })
    }

    const handleAmount = (event) => {
        const id = event.target.id.split("_")[1];
        const value = event.target.value;
        let varia = variable;
        Object.keys(varia).forEach(key =>{
            if(id === key){
                varia[key].amount = value;
            }
        })
    }

    const handlePrixTotal = (event) => {
        const inputEvent = event.target;
        var prices = document.getElementsByName("price");
        let total = 0;
        for(const p of prices){
            let valuetmp = p.value;
            if(valuetmp===""){
                valuetmp = "0";
            }
            total = total + parseInt(valuetmp);
        }
        inputEvent.value = total;
        setPrixTotal(total);
    }

    const handleAddVar = (event) => {
        event.preventDefault();
        let numVarTmp = parseInt(numVar);
        numVarTmp++;
        setNumVar(numVarTmp);
    }

    const handleAddAccessoires = (event) => {
        event.preventDefault();
        let numAccessoiresTmp = parseInt(numAccessoires);
        numAccessoiresTmp++;
        setNumAccessoires(numAccessoiresTmp);
    }

    const handleRemoveVar = (event) => {
        event.preventDefault();
        let varia = variable;
        let i = 1;
        Object.keys(varia).forEach(key =>{
            if("var" + i === key){
                i++;
            }
        })
        i--;
        Object.keys(varia).forEach(key => {
            if("var" + i === key){
                delete varia[key];
            }
        });
        let numVarTmp = parseInt(numVar);
        numVarTmp--;
        setNumVar(numVarTmp);
    }

    const handleRemoveAccessoires = (event) => {
        event.preventDefault();
        let varia = variable;
        let i = 1;
        Object.keys(varia).forEach(key =>{
            if("varAccessoires" + i === key){
                i++;
            }
        })
        i--;
        Object.keys(varia).forEach(key => {
            if("varAccessoires" + i === key){
                delete varia[key];
            }
        });
        let numAccessoiresTmp = parseInt(numAccessoires);
        numAccessoiresTmp--;
        setNumAccessoires(numAccessoiresTmp);
    }

    const optionType = [
        {
            id: 1,
            value: "",
            name: "Choisissez un type de projet",
            disabled: true,
            hidden: true
        },
        {
            id: 2,
            value: "vmc",
            name: "VMC Double flux"
        },
        {
            id: 3,
            value: "poele",
            name: "Poêle à granulé"
        },
        {
            id: 4,
            value: "cesol",
            name: "Chauffe eau solaire"
        },
        {
            id: 5,
            value: "pac",
            name: "Pompe à chaleur"
        },
        {
            id: 6,
            value: "chg",
            name: "Chaudière à granulé"
        },
        {
            id: 7,
            value: "ch",
            name: "Chaudière à gaz"
        },
        {
            id: 8,
            value: "ssc",
            name: "Système solaire combiné"
        },
        {
            id: 9,
            value: "csp",
            name: "Centrale solaire photovoltaique"
        }
    ]

    const optionTypeAppoint = [
        {
            id: 1,
            value: "",
            name: "Choisissez un type de projet",
            disabled: true,
            hidden: true
        },
        {
            id: 2,
            value: "pac",
            name: "Pompe à chaleur"
        },
        {
            id: 3,
            value: "chb",
            name: "Chaudière biomass"
        },
        {
            id: 4,
            value: "ch",
            name: "Chaudière à gaz"
        },
        {
            id: 5,
            value: "chf",
            name: "Chaudière fioul"
        }
    ]

    const optionObject = [
        {
            id: 1,
            value: "",
            name: "Choisissez un objet",
            disabled: true,
            hidden: true
        },
        {
            id: 2,
            value: "Centrale solaire photovoltaique en autoconsommation avec revente de surplus",
            name: "Centrale solaire photovoltaique en autoconsommation avec revente de surplus"
        },
        {
            id: 3,
            value: "Centrale solaire photovoltaique en autoconsommation avec revente de surplus avec gestion domotique",
            name: "Centrale solaire photovoltaique en autoconsommation avec revente de surplus avec gestion domotique"
        },
        {
            id: 4,
            value: 'Centrale solaire photovoltaique en autoconsommation avec revente de surplus avec "Onduleur HYDRID HYAWAI" stockage d\'énergie par les batteries',
            name: 'Centrale solaire photovoltaique en autoconsommation avec revente de surplus avec "Onduleur HYDRID HYAWAI" stockage d\'énergie par les batteries'
        },
        {
            id: 5,
            value: 'Centrale solaire photovoltaique TRINA SMA en revente à EDF',
            name: 'Centrale solaire photovoltaique TRINA SMA en revente à EDF'
        },
        {
            id: 6,
            value: 'Centrale solaire photovoltaique TRINA SMA autoconsommation avec en revente à EDF du surplus',
            name: 'Centrale solaire photovoltaique TRINA SMA autoconsommation avec en revente à EDF du surplus'
        }
    ]

    const optionDel = [
        {
            id: 1,
            value: "",
            name: "Choisissez un délégataire",
            disabled: true,
            hidden: true
        },
        {
            id: 2,
            value: "edfPE",
            name: "EDF Prime Energie"
        },
        {
            id: 3,
            value: "edfS",
            name: "EDF Synerciel"
        },
        {
            id: 4,
            value: "total",
            name: "Total"
        }
    ]

    const optionStatus = [
        {
            id: 1,
            value: "",
            name: "Choisissez le status du ménage de la maison.",
            disabled: true,
            hidden: true
        },
        {
            id: 2,
            value: "BLEU",
            name: "BLEU"
        },
        {
            id: 3,
            value: "JAUNE",
            name: "JAUNE"
        },
        {
            id: 4,
            value: "VIOLET",
            name: "VIOLET"
        },
        {
            id: 5,
            value: "ROSE",
            name: "ROSE"
        },
    ]

    const handleStatutMenage = (event) => {
        setStatutMenage(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setMessError("");
        setMessValid("");
        const buttonSubmit = document.getElementById("submit");
        buttonSubmit.disabled = true;
        var submit = {
            name,
            dateDevis,
            object,
            delegate,
            typeProject,
            marque,
            modele,
            numEtude,
            numParcelle,
            prixProjet,
            statutMenage,
            num_dossier: numDossier,
            checkPrimeRenov,
            checkPrimeCoupPouce,
            prixTotal,
            typeAppoint,
            var: variable
        }
        if(marqueExist !== ""){
            submit["marqueExist"] = marqueExist;
            submit["modeleExist"] = modeleExist;
            submit["puissance"] = puissance;
        }
        if(tensionLog !== "-1"){
            submit["tensionLog"] = tensionLog;
            submit["typeEmetteur"] = typeEmetteur;
            submit["regimeEau"] = regimeEau;
        }
        const listProString = JSON.stringify(submit);
        const bufferlistPro = Buffer.from(listProString, "utf8");
        const base64 = bufferlistPro.toString("base64");
        axios.post(process.env.REACT_APP_URL_API + `project/add/${customerId}/${projectId}`, {
            base64: base64
        }, {
            headers: { 
                'Authorization': tokenHeader
            }
        }).then((response) => {
            let project = response.data;
            window.scrollTo(0,0);
            setMessValid("Le projet a bien été créer. Patientez quelques instants, vous serez redirigé.");
            setTimeout(() => {
                window.location = window.location.origin + "/addFileProjectRedirect?id=" + project.id + "&type=" + typeProject;
            }, 5000);
        }).catch((error) => {
            if(error.response){
                if(error.response.status === 401){
                    var storage = window.localStorage;
                    storage.clear();
                    window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                    window.location = window.location.origin;
                }else{
                    window.scrollTo(0,0);
                    if(Array.isArray(error.response.data.message)){
                        let merr = "";
                        for(const mess of error.response.data.message){
                            merr += mess + "</br>";
                        }
                        setMessError(merr);
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            }
            buttonSubmit.disabled = false;
        });
    }

    const handleBack = () => {
        var history = window.history;
        history.back();
    }

    return(
        <Container>
            <Button id="back" name="Retour" onClick={ handleBack }/>
            <Form onSubmit={handleSubmit}>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                { messValid !== "" ? <Label name={ messValid }/> : <></> }
                <DivGroupInput>
                    <DivInput>
                        <Label name="Type de projet :"/>
                        <Select defaultValue={ typeProject } option={ optionType } id="name" required={true} name="name" onChange={ handleName } />
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Date Devis :"/>
                        <Input value={dateDevis} type="date" id="date_devis" required={true} name="date_devis" onChange={ handleDateDevis } />
                    </DivInput>
                </DivGroupInput>


                { typeProject === "csp" ?
                    <DivGroupInput>
                        <DivInput>
                            <Label name="Objet :"/>
                            <Select defaultValue={ object } option={ optionObject } id="object" required={true} name="object" onChange={ handleObject } />
                        </DivInput>
                    </DivGroupInput>
                    :
                    <DivGroupInput>
                        <DivInput>
                            <Label name="Délégataire :"/>
                            <Select defaultValue={ delegate } option={ optionDel } id="delegate" required={true} name="delegate" onChange={ handleDelegate } />
                        </DivInput>
                    </DivGroupInput>
                }

                { ['pac', 'ch', 'chg', 'ssc'].includes(typeProject) ?
                    <>
                        <Label name="Appareil existant :"/>
                        <DivGroupInput id="DivMult">
                            <DivInput>
                                <Label name="Marque :"/>
                                <Input value={marqueExist} id="marque_exist" required={true} name="marque_exist" onChange={ handleMarqueExist } />
                            </DivInput>
                            <DivInput>
                                <Label name="Modèle :"/>
                                <Input value={modeleExist} id="modele_exist" required={true} name="modele_exist" onChange={ handleModeleExist } />
                            </DivInput>
                            { typeProject !== "ssc" ?
                                <></>
                                :
                                <DivInput>
                                    <Label name="Type d'appoint :"/>
                                    <Select defaultValue={ typeAppoint } option={ optionTypeAppoint } id="typeAppoint" required={true} name="typeAppoint" onChange={ handleTypeAppoint } />
                                </DivInput>
                            }
                        </DivGroupInput>
                    </>
                    :
                    <></>
                }
                
                { typeProject === "csp" ?
                    <></>
                    :
                    <>
                        <DivGroupInput>
                            <DivInput>
                                <Label name="Numéro du dossier :"/>
                                <Input value={numDossier} id="numDossier" type="text" required={true} name="numDossier" onChange={ handleNumDossier }/>
                            </DivInput>
                        </DivGroupInput>
                        <DivGroupInput>
                            <DivCheckBox>
                                <Input type="checkbox" id="checkboxPrimeRenov" name="checkboxPrimeRenov" onChange={ handleCheckPrimeRenov } />
                                <Label name="Déduire la prime MaPrimeRenov"/>
                            </DivCheckBox>
                        </DivGroupInput>
                    </>
                }

                {['ssc'].includes(typeProject) ?
                    <DivGroupInput>
                        <DivCheckBox>
                            <Input type="checkbox" id="checkboxPrimeCoupPouce" name="checkboxPrimeCoupPouce" onChange={ handleCheckPrimeCoupPouce } />
                            <Label name="Prime coup de pouce"/>
                        </DivCheckBox>
                    </DivGroupInput>
                    :
                    <></>
                }

                { delegate === "total" ?
                    <>
                        <DivGroupInput id="DivMult">
                            <DivInput>
                                <Label name="Numéro de l'étude de dimentionnement :"/>
                                <Input value={numEtude} id="numEtude" required={true} name="numEtude" onChange={ handleNumEtude } />
                            </DivInput>
                            <DivInput>
                                <Label name="Numéro de la parcelle cadastrale :"/>
                                <Input value={numParcelle} id="numParcelle" required={true} name="numParcelle" onChange={ handleNumParcelle } />
                            </DivInput>
                        </DivGroupInput>
                    </>
                    :
                    <></>
                }

                { Object.keys(optionTension).length !== 0 && Object.keys(optionTypeEmetteur).length !== 0 ?
                    <>
                        <DivGroupInput id="DivMult">
                            <DivInput>
                                <Label name="Tension du logement :"/>
                                <Select defaultValue={ tensionLog } option={ optionTension } id="tension" required={true} name="tension" onChange={ handleTension } />
                            </DivInput>
                            <DivInput>
                                <Label name="Type d'émetteur :"/>
                                <Select defaultValue={ typeEmetteur } option={ optionTypeEmetteur } id="typeEmetteur" required={true} name="typeEmetteur" onChange={ handleTypeEmetteur } />
                            </DivInput>
                            <DivInput>
                                <Label name="Regime d'eau :"/>
                                <Select defaultValue={ regimeEau } option={ optionRegimeEau } id="regimeEau" required={true} name="regimeEau" onChange={ handleRegimeEau } />
                            </DivInput>
                        </DivGroupInput>
                    </>
                    :
                    <></>
                }
                { typeProject !== "csp" ?
                <>
                    { ['pac', 'ch', 'chg'].includes(typeProject) ?
                    <>
                        <DivGroupInput id="DivMult">
                            <DivInput>
                                <Label name="Marque :"/>
                                <Select defaultValue={ marque } option={ optionMarque } id="marque" required={true} name="marque" onChange={ handleMarque } />
                            </DivInput>
                            <DivInput>
                                <Label name="Modèle :"/>
                                <Select defaultValue={ modele } option={ optionModele } id="modele" required={true} name="modele" onChange={ handleModele } />
                            </DivInput>
                        </DivGroupInput>
                        <DivGroupInput id="DivMult">
                            <DivInput>
                                <Label name="Puissance (kw) :"/>
                                <Select defaultValue={ puissance } option={ optionPuissace } id="puissance" required={true} name="puissance" onChange={ handlePuissance } />
                            </DivInput>
                            <DivInput>
                                <Label name="Prix du modèle :"/>
                                <Input value={prixProjet} id="priceModele" type="text" required={true} name="price" onChange={ handlePrixProjet }/>
                            </DivInput>
                        </DivGroupInput>
                    </>
                    :
                    <DivGroupInput id="DivMult">
                        <DivInput>
                            <Label name="Marque :"/>
                            <Select defaultValue={ marque } option={ optionMarque } id="marque" required={true} name="marque" onChange={ handleMarque } />
                        </DivInput>
                        <DivInput>
                            <Label name="Modèle :"/>
                            <Select defaultValue={ modele } option={ optionModele } id="modele" required={true} name="modele" onChange={ handleModele } />
                        </DivInput>
                        { typeProject !== "cesol" ?
                            <DivInput>
                                <Label name="Prix du modèle :"/>
                                <Input value={prixProjet} id="priceModele" type="text" required={true} name="price" onChange={ handlePrixProjet }/>
                            </DivInput>
                            :
                            <></>
                        }
                    </DivGroupInput>
                    }
                </>
                :
                <></>
                }
                
                {typeProject !== "csp" ?
                    <DivGroupInput>
                        <DivInput>
                            <Label name="Statut du ménage (selon critère ANAH) :"/>
                            <Select defaultValue={''} option={ optionStatus } id="statutMenage" required={true} name="statutMenage" onChange={ handleStatutMenage }/>
                        </DivInput>
                    </DivGroupInput>
                    :
                    <></>
                }
                
                { numVar !== 0 ?
                    <>
                        {(() => {
                            let tmp = [];
                            for(let i = 1; i <= numVar; i++) {
                                tmp.push(
                                <DivGroupInput key={i}>
                                    <DivInput id="selectVar">
                                        <Label name={`Variable n°${i}:`}/>
                                        <Select defaultValue={""} key={i} option={ optionVar } id={`var${i}`} required={true} name={`variable ${i}`} onChange={ handleVar } />
                                    </DivInput>
                                    <DivInput id="inputVar">
                                        <Label name="Prix :"/>
                                        <Input id={`price_var${i}`} type="text" required={true} name={`price`} onChange={ handlePrice } />
                                    </DivInput>
                                </DivGroupInput>
                                )
                            }
                            return tmp;
                        })()}
                    </>
                :
                <></>
                }
                <DivGroupInput id="DivMult">
                    { numVar < maxVar ?
                        <DivInput>
                            <Button id="add_var" name="Ajouter une variable" onClick={ handleAddVar }/>
                        </DivInput>
                    :
                    <></>
                    }
                    { numVar > minVar ?
                        <DivInput>
                            <Button id="add_var" name="Supprimer une variable" onClick={ handleRemoveVar }/>
                        </DivInput>
                        :
                        <></>
                    }
                </DivGroupInput>

                { Object.keys(optionAccessoires).length !== 0 && Object.keys(optionForfait).length !== 0 ?
                    <>
                        {(() => {
                            let tmp = [];
                            for(let i = 1; i <= numAccessoires; i++) {
                                tmp.push(
                                <DivGroupInput key={i}>
                                    <DivInput id="selectVar">
                                        <Label name={`Accessoire n°${i}:`}/>
                                        <Select defaultValue={""} key={i} option={ optionAccessoires } id={`varAccessoires${i}`} required={true} name={`variable accessoire ${i}`} onChange={ handleVar } />
                                    </DivInput>
                                    <DivInput id="inputVar">
                                        <Label name="Prix :"/>
                                        <Input id={`price_varAccessoires${i}`} type="text" required={true} name={`price`} onChange={ handlePrice } />
                                    </DivInput>
                                </DivGroupInput>
                                )
                            }
                            return tmp;
                        })()}


                        <DivGroupInput id="DivMult">
                            { numAccessoires < 2 ?
                                <DivInput>
                                    <Button id="add_accessoires" name="Ajouter un accessoire" onClick={ handleAddAccessoires }/>
                                </DivInput>
                            :
                            <></>
                            }
                            { numAccessoires > 0 ?
                                <DivInput>
                                    <Button id="remove_accessoires" name="Supprimer un accessoire" onClick={ handleRemoveAccessoires }/>
                                </DivInput>
                                :
                                <></>
                            }
                        </DivGroupInput>

                        {(() => {
                            let tmp = [];
                            for(let i = 1; i <= 1; i++) {
                                tmp.push(
                                <DivGroupInput key={i}>
                                    <DivInput id="selectVar">
                                        <Label name={`Variable de forfait n°${i}:`}/>
                                        <Select defaultValue={""} key={i} option={ optionForfait } id={`varForfait${i}`} required={true} name={`variable de forfait ${i}`} onChange={ handleVar } />
                                    </DivInput>
                                    <DivInput id="inputVar">
                                        <Label name="Prix :"/>
                                        <Input id={`price_varForfait${i}`} type="text" required={true} name={`price`} onChange={ handlePrice } />
                                    </DivInput>
                                </DivGroupInput>
                                )
                            }
                            return tmp;
                        })()}
                    </>
                :
                <></>
                }

                <DivGroupInput id="prixTotal">
                    <DivInput>
                        <Label name="Prix Total :"/>
                        <Input value={prixTotal} id="price_total" type="text" required={true} name="price_total" onChange={ handlePrixTotal }/>
                    </DivInput>
                </DivGroupInput>

                <Submit>
                    <Input id="submit" type="submit" value="Suivant" />
                </Submit>
                
            </Form>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 25px;

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 1350px){
        margin-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;

        #back{
            font-size: 15px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: start;

    label{
        text-align: justify;
        margin-bottom: 1%;
        margin-top: 1%;
        font-size: 22px;
        font-weight: 700;
    }

    #messError{
        color: red;
    }

    #DivMult{
        select{
            width: 100%;
        }

        div{
            padding: 0% 0.5%
        }

        @media screen and (max-width: 950px){
            select, input {
                font-size: 16px;
            }
        }

        @media screen and (max-width: 750px){
            select, input {
                font-size: 22px;
            }
        }
    }

    #prixTotal{
        justify-content: end;
        div{
            width: 35%;
        }
    }
`;

const DivInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2% 0%;
    width: 100%;

    @media screen and (max-width: 750px){
        width: auto;
    }

    input{
        border-radius: 5%;
        font-size : 18px;
    }

    select{
        font-size: 22px;
    }
`;

const DivCheckBox = styled(DivInput)`
    flex-direction: row;
    align-items: center;

    label{
        text-align: justify;
        font-size: 18px;
    }
`;

const DivGroupInput = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    #selectVar{
        width: 65%;
        padding: 0% 1% 0% 0%;
    }

    #inputVar{
        width: 35%;
        padding: 0% 0% 0% 1%;
    }

    @media screen and (max-width: 750px){
        flex-direction: column;

        #selectVar{
            width: 100%;
        }
    }
`;

const Submit = styled.div`
    margin: 5% 0%;
    width: 100%;

    #submit{
        width: 50%;
        font-size: 18px;
        border-radius: 100px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 750px){
        #submit{
            font-size: 15px;
        }
    }
`;

export default FormNewProject;