import React from "react";
import { Redirect } from "react-router";

const ComptesRedirect = () => {
    const status = window.location.search.split("=")[1];
    return <Redirect to={{
        pathname: "/comptes",
        state: {
            status: status
        }
    }}/>
}

export default ComptesRedirect;