import React from "react";
import { Redirect } from "react-router";

const CustomerRedirect = () => {
    const cId = window.location.search.split("=")[1];
    return <Redirect to={{
        pathname: "/customer",
        state: {
            customerId: cId
        }
    }}/>
}

export default CustomerRedirect;