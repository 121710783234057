import React from "react";

const Label = (props) => {
    return(
        <>
            <label id={props.id} style={props.style}>{props.name}</label>
        </>
    );
}

export default Label;