import React from "react";
import { Redirect } from "react-router";
import { SignatureTemplate } from "../../components/templates";

const Signature = props => {
    const { projectId } = props.location.state;
    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404" />
    }else{
        return (
            <>
                <SignatureTemplate projectId={ projectId }/>
            </>
        )
    }
}

export default Signature;