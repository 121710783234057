import React from "react";
import { Redirect } from "react-router";
import { NewCustomerTemplate } from "../../components/templates";

const NewCustomer = () => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404" />
    }else{
        return (
            <>
                <NewCustomerTemplate/>
            </>
        )
    }
}

export default NewCustomer;