import axios from "axios";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Ahref, Label, Li } from "../../atoms";

const BtnHome = () => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [typeUser, setTypeUser] = React.useState("");
    const [nbNewProject, setNbNewProject] = React.useState(0);
    const [nbInProgressProject, setNbInProgressProject] = React.useState(0);

    useEffect(() => {
        const fetchCustomer = () => {
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                setTypeUser(response.data.type);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        window.alert("Une erreur est survenue.");
                        window.location = window.location.origin + "/404";
                    }
                }
            });
            if(typeUser === "admin"){
                axios.get(process.env.REACT_APP_URL_API + 'project/status', {
                    headers : {
                        'Authorization': tokenHeader
                    }
                }).then((response) => {
                    response.data.map((e) => {
                        if(e.status === "Nouveau"){
                            setNbNewProject(e.number);
                        }else{
                            setNbInProgressProject(e.number);
                        }
                        return true;
                    })
                }).catch((error) => {
                    window.alert("Une erreur est survenue. Veuillez réactualiser la page.");
                })
            }
        };

        fetchCustomer();
    }, [tokenHeader, typeUser]);

    const handleDeco = () => {
        var storage = window.localStorage;
        storage.clear();
    }

    return(
        <Container>
            <Ul>
            {(() => {
                switch (typeUser) {
                    case "admin":
                        return(
                        <>
                            <li>
                                <Ahref name="Nouveau" href="/projectsRedirectStatus?status=nouveau"/>
                                { nbNewProject === 0 ?
                                    <></>
                                    :
                                    <Label id="nbNewProject" name={nbNewProject}/>
                                }
                            </li>
                            <li>
                                <Ahref name="En cours" href="/projectsRedirectStatus?status=encours"/>
                                { nbInProgressProject === 0 ?
                                <></>
                                :
                                <Label id="nbInProgressProject" name={nbInProgressProject}/>
                                }
                            </li>
                            <Li name="Fini" href="/finishProjects?status=finish"/>
                        </>)
                    case "regie":
                        return(
                        <>
                            <Li name="Vue global" href="/projects"/>
                            <Li name="Projet par technicien" href="/comptes"/>
                        </>)
                    case "tech":
                        return(
                        <>
                            <Li name="Nouveau dossier" href="/customerProject"/>
                            <Li name="Mes dossiers" href="/projects"/>
                        </>)
                    default:
                        return(
                        <>
                        </>)
                }
            })()}
                { typeUser !== "admin" && typeUser !== "" ?
                    <Li href="/" name="Déconnexion" onClick={handleDeco}/>
                :
                <></>
                }
            </Ul>
            { typeUser === "admin" ?
            <>
                <Ul>
                    <Li name="Annuler" href="/projectsRedirectStatus?status=annule"/>
                    <Li name="Supprimer" href="/projectsRedirectStatus?status=supprime"/>
                </Ul>
                <Ul>
                    <Li id="admin" name="Comptes" href="/comptes"/>
                    <Li id="admin" href="/" name="Déconnexion" onClick={handleDeco}/>
                </Ul>
            </>
            :
            <></>
            }
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 75%;
    margin: auto auto;
    font-size: 25px;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 75px;

    @media screen and (max-width: 750px){
        font-size: 15px;
    }
`;

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2%;

    li{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 5%;
    }

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5%;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }

    label{
        text-align: justify;
        font-size: 16px;
        font-weight: 700;
        color: red;
        position: absolute;
        margin: 5px 0%;
        border: 1px solid red;
        padding: 0.25% 0.5%;
        border-radius: 100%;
        box-shadow: 5px 4px 8px black;
    }

    #admin{
        width: 75%;
    }

    #nbNewProject{
        left: 31.5%;

        @media screen and (max-width: 750px){
            font-size: 12px;
        }
    }

    #nbInProgressProject{
        left: 55.5%;

        @media screen and (max-width: 750px){
            font-size: 12px;
        }
    }
`;

export default BtnHome;