import React from "react";
import styled from "styled-components";
import { BtnHome } from "../../molecules";

const HomeOrga = () => {

    return(
        <Container>
            <BtnHome/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
    height: inherit;
`;

export default HomeOrga;