import React from "react";
import styled from "styled-components";
import { AddFileProjectOrga } from "../../organisms";

const AddFileProjectTemplate = ({ projectId, typeProject }) => {

    return (
        <Container>
            <AddFileProjectOrga projectId={ projectId } typeProject={ typeProject }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default AddFileProjectTemplate;