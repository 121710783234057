import React, { useEffect } from "react";
import { Button, Input, Label, Select } from "../../atoms";
import styled from "styled-components";
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';

const FormAddFileProkject = ({ projectId, typeProject }) => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [messError, setMessError] = React.useState("");
    const [messValid, setMessValid] = React.useState("");
    const [varProject, setVarProject] = React.useState({});
    const [variable, setVariable] = React.useState({});
    const [files, setFiles] = React.useState([]);

    const handleDeleteFile = React.useCallback((e, listFiles) => {
        e.preventDefault();
        const name = e.target.id;
        const divImg = e.target.parentNode;
        const parentNode = divImg.parentNode;
        const input = parentNode.previousSibling;
        let nb = parseInt(input.getAttribute("nb"));
        nb--;
        setTimeout(() => {
            input.setAttribute("nb", nb);
        }, 1);
        let fileByteArray = listFiles;
        for(let i = 0; i < fileByteArray.length; i++){
            if(fileByteArray[i].title === name){
                fileByteArray.splice(i, 1);
            }
        }
        parentNode.removeChild(divImg);
        setFiles(fileByteArray);
    }, [])

    const handleFiles = React.useCallback((event, listFiles) => {
        const userFiles = event.target.files;
        let divFiles = event.target.nextElementSibling;
        let fileByteArray = listFiles;
        setMessError("");
        if(userFiles){
            for(const file of userFiles){
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async () => {
                    let fileNotAccept = false;
                    let exist = event.target.attributes["nb"].value;
                    let filename = file.name.split(".");
                    filename = event.target.id;
                    if(exist !== "0"){
                        filename = filename + "_" + exist;
                    }
                    exist++;
                    event.target.attributes["nb"].value = exist;
                    let fil = {};
                    let tmp = reader.result.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                    let typeTmp = tmp.split("/")[1];
                    let data = tmp.split("/")[0];
                    if(data === "image"){
                        const divFile = document.createElement("div");
                        divFile.id = "divFile";
                        const img = document.createElement('img');
                        img.setAttribute("src", reader.result);
                        img.setAttribute("style", "width: 100%");
                        const imgTitle = document.createElement("label");
                        imgTitle.innerText = filename;
                        const deleteImg = document.createElement("a");
                        deleteImg.href = "#";
                        deleteImg.innerText = "X";
                        deleteImg.id = filename;
                        deleteImg.addEventListener("click", e => { handleDeleteFile(e, listFiles) });
                        divFile.appendChild(img);
                        divFile.appendChild(imgTitle);
                        divFile.appendChild(deleteImg);
                        divFiles.appendChild(divFile);
                    }else{
                        fileNotAccept = true;
                    }
                    if(!fileNotAccept){
                        fil.title = filename;
                        const pdf = await PDFDocument.create();
                        let image;
                        if(typeTmp === "png"){
                            image = await pdf.embedPng(reader.result);
                        }else if(typeTmp === "jpeg" || typeTmp === "jpg"){
                            image = await pdf.embedJpg(reader.result);
                        }else{
                            image = undefined;
                        }
                        if(image){
                            const pageImg = pdf.addPage();
                            const ScaleImg = image.scale(1);
                            let width = ScaleImg.width > pageImg.getWidth() ? ScaleImg.width / 2 : ScaleImg.width;
                            let height = ScaleImg.height > pageImg.getHeight() ? ScaleImg.height / 2 : ScaleImg.height;
                            while(width > pageImg.getWidth()){
                                width = width / 1.5;
                                height = height / 1.5;
                            }
                            while(height > pageImg.getHeight()){
                                width = width / 1.5;
                                height = height / 1.5;
                            }
                            pageImg.drawImage(image, {
                                x: 10,
                                y: pageImg.getHeight() - height - 10,
                                width: width,
                                height: height,
                            });
                            const U8A = await pdf.saveAsBase64();
                            fil.result = U8A;
                            fil.type = "pdf";
                            fileByteArray.push(fil);
                        }else{
                            window.scrollTo(0,0);
                            setMessError("Le fichier que vous essayez d'ajouter n'est pas accepté.");
                        }
                    }else{
                        window.scrollTo(0,0);
                        setMessError("Le fichier que vous essayez d'ajouter n'est pas accepté.");
                    }
                }
                reader.onerror = (error) => {
                    setMessError(error);
                };                
            }
        }
        setFiles(fileByteArray);
    }, [handleDeleteFile])
    
    const handleSelectZone = React.useCallback((event, regles, variaProject, listFiles) => {
        const id = event.target.id;
        const value = event.target.value;
        const name = event.target.selectedOptions[0].label;
        const divZone = event.target.parentNode;
        const divVariables = divZone.parentNode;
        let lastChild = divZone.lastChild;
        if(lastChild.id === "divReglesZone"){
            divZone.removeChild(divZone.lastChild);
        }
        let div = document.getElementById("divVar");
        if(div){
            divVariables.removeChild(div);
        }
        const divReglesZone = document.createElement("div");
        divReglesZone.id = "divReglesZone";
        let i = 1;
        for(const reg of regles["default"]){
            const devReg = document.createElement("div");
            const checkReg = document.createElement("input");
            checkReg.type = "checkbox";
            checkReg.id = "regle_" + i;
            checkReg.required = true;
            const labelReg = document.createElement("label");
            labelReg.innerHTML = reg;
            labelReg.setAttribute("for","regle_" + i);
            i++;
            devReg.appendChild(checkReg);
            devReg.appendChild(labelReg);
            divReglesZone.appendChild(devReg);
        }
        const divVar = document.createElement("div");
        divVar.classList = divZone.classList;
        divVar.id = "divVar";
        const label = document.createElement("label");
        const input = document.createElement("input");
        const divFiles = document.createElement("div");
        divFiles.id = "divFiles";
        divFiles.setAttribute("name", "divFiles");
        switch(value){
            case "1":
                label.innerText = "Photo des boisseaux : ";
                input.type = "file";
                input.accept = "image/*";
                input.id = "boisseaux";
                input.name = "file";
                input.setAttribute("nb", "0");
                input.addEventListener("change", e => { handleFiles(e, listFiles) });
                break;
            case "2":
                for(const reg of regles["2"]){
                    const devReg = document.createElement("div");
                    const checkReg = document.createElement("input");
                    checkReg.type = "checkbox";
                    checkReg.id = "regle_" + i;
                    checkReg.required = true;
                    const labelReg = document.createElement("label");
                    labelReg.innerHTML = reg;
                    labelReg.setAttribute("for","regle_" + i);
                    i++;
                    devReg.appendChild(checkReg);
                    devReg.appendChild(labelReg);
                    divReglesZone.appendChild(devReg);
                }
                label.innerText = "Photo de la sortie murale extérieur : ";
                input.type = "file";
                input.accept = "image/*";
                input.id = "sortie_murale_exterieur";
                input.name = "file";
                input.setAttribute("nb", "0");
                input.addEventListener("change", e => { handleFiles(e, listFiles) });
                break;
            case "3":
                for(const reg of regles["3"]){
                    const devReg = document.createElement("div");
                    const checkReg = document.createElement("input");
                    checkReg.type = "checkbox";
                    checkReg.id = "regle_" + i;
                    checkReg.required = true;
                    const labelReg = document.createElement("label");
                    labelReg.innerHTML = reg;
                    labelReg.setAttribute("for","regle_" + i);
                    i++;
                    devReg.appendChild(checkReg);
                    devReg.appendChild(labelReg);
                    divReglesZone.appendChild(devReg);
                }
                label.innerText = "Photo de la sortie cheminée/ventouse : ";
                input.type = "file";
                input.accept = "image/*";
                input.id = "sortie_toiture";
                input.name = "file";
                input.setAttribute("nb", "0");
                input.addEventListener("change", e => { handleFiles(e, listFiles) });
                break;
            default:
                break;
        }
        divVar.appendChild(label);
        divVar.appendChild(input);
        divVar.appendChild(divFiles);
        divVariables.appendChild(divVar);
        divZone.appendChild(divReglesZone);
        let varia = variaProject;
        varia[id] = {
            value,
            name
        }
        setVariable(varia);
    }, [handleFiles])

    useEffect(() => {
        const fetchCustomer = () => {
            setMessError("");
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                if(response.data.type !== "tech"){
                    window.alert("Vous n'êtes pas autorisé à effectuer cette action");
                    window.location = window.location.origin + "/404";
                }
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
            axios.get(process.env.REACT_APP_URL_API + `project/variable/${typeProject}/${projectId}`, {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                let listFiles = response.data.files;
                let fileArray = [];
                let variaProject = response.data.variable;
                setVarProject(variaProject);
                if(response.data.varSupp){
                    let varSupp = response.data.varSupp;
                    Object.keys(varSupp).forEach((key) => {
                        const select = document.getElementById(key);
                        select.value = varSupp[key].value;
                        if(select.id === "zone"){
                            var evt = new Event('change', { "bubbles": true });
                            select.dispatchEvent(evt);
                            let exist = true;
                            let i = 1;
                            while(exist){
                                const checkbox = document.getElementById("regle_" + i);
                                if(checkbox === null){
                                    exist = false;
                                    break;
                                }
                                checkbox.checked = true;
                                i++;
                            }
                        }
                    })
                    setVariable(varSupp);
                }
                setTimeout(() => {
                    const divFiles = document.getElementsByName("divFiles");
                    Object.keys(listFiles).forEach((key) => {
                        let fil = {
                            title: listFiles[key].filename,
                            result: listFiles[key].data,
                            type: "pdf"
                        }
                        fileArray.push(fil);
                    })
                    setFiles(fileArray);
                    Object.keys(listFiles).forEach((key) => {
                        let name = listFiles[key].filename.split("_");
                        let number = new RegExp("[0-9]")
                        if(number.test(name[name.length - 1])){
                            name.pop();
                        }
                        name = name.join("_");
                        Object.keys(divFiles).forEach((key2) => {
                            const inputFile = divFiles[key2].previousSibling;
                            if(inputFile.id === name){
                                let input = document.getElementById(name);
                                let nb = input.getAttribute("nb");
                                nb++;
                                input.setAttribute("nb", nb);
                                let base64 = "data:application/pdf;base64," + listFiles[key].data;
                                const divFile = document.createElement("div");
                                divFile.id = "divFile";
                                const embed = document.createElement("embed");
                                embed.setAttribute("src", base64);
                                embed.setAttribute("width", "200");
                                embed.setAttribute("title", listFiles[key].filename);
                                embed.setAttribute("type", "application/pdf")
                                const label = document.createElement("label");
                                label.innerHTML = listFiles[key].filename;
                                const a = document.createElement("a");
                                a.id = listFiles[key].filename;
                                a.href = "";
                                a.innerHTML = "X";
                                a.addEventListener("click", e => { handleDeleteFile(e, fileArray) });
                                divFile.appendChild(embed);
                                divFile.appendChild(label);
                                divFile.appendChild(a);
                                divFiles[key2].appendChild(divFile);
                            }
                        })
                    })
                }, 1);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
        };

        fetchCustomer();
    }, [tokenHeader, typeProject, projectId, handleSelectZone, handleDeleteFile]);

    const handleInput = (event) => {
        const id = event.target.id;
        const value = event.target.value;
        let name;
        if(event.target.type === "text"){
            name = value
        }else{
            name = event.target.selectedOptions[0].label;
        }
        let varia = variable;
        varia[id] = {
            value,
            name
        }
        setVariable(varia);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessError("");
        setMessValid("");
        const buttonSubmit = document.getElementById("submit");
        buttonSubmit.disabled = true;
        var submit = {
            id: projectId,
            var: variable,
            files: files
        }
        const listProString = JSON.stringify(submit);
        const bufferlistPro = Buffer.from(listProString, "utf8");
        const base64 = bufferlistPro.toString("base64");
        axios.post(process.env.REACT_APP_URL_API + 'project/addFileProject', {
            base64: base64
        }, {
            headers: { 
                'Authorization': tokenHeader
            }
        }).then((response) => {
            window.scrollTo(0,0);
            setMessValid("Les fichiers ont bien été traité. Patientez quelques instants, vous serez redirigé.");
        }).catch((error) => {
            if(error.response){
                if(error.response.status === 401){
                    var storage = window.localStorage;
                    storage.clear();
                    window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                    window.location = window.location.origin;
                }else{
                    window.scrollTo(0,0);
                    if(Array.isArray(error.response.data.message)){
                        let merr = "";
                        for(const mess of error.response.data.message){
                            merr += mess + "</br>";
                        }
                        setMessError(merr);
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            }
            buttonSubmit.disabled = false;
        });
        await axios.post(process.env.REACT_APP_URL_API + 'project/changeToPdf', {
            idProject: projectId
        }, {
            headers: { 
                'Authorization': tokenHeader
            }
        }).then((response) => {
            let project = response.data;
            window.scrollTo(0,0);
            setMessValid("Les fichiers ont bien été traité. Patientez quelques instants, vous serez redirigé.");
            setTimeout(() => {
                window.location = window.location.origin + "/signatureRedirect?id=" + project.id;
            }, 2000);
        }).catch((error) => {
            if(error.response){
                if(error.response.status === 401){
                    var storage = window.localStorage;
                    storage.clear();
                    window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                    window.location = window.location.origin;
                }else{
                    window.scrollTo(0,0);
                    setMessError(error.response.data.message);
                }
            }
            buttonSubmit.disabled = false;
        })
    }

    const handleBack = () => {
        var history = window.history;
        history.back();
    }

    return(
        <Container>
            <Button id="back" name="Retour" onClick={ handleBack }/>
            <Form onSubmit={handleSubmit}>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                { messValid !== "" ? <Label name={ messValid }/> : <></> }

                {(() => {
                    let tmp = []; 
                    Object.keys(varProject).forEach(key => {
                        if(varProject[key].type !== "select"){
                            tmp.push(
                                <DivGroupInput key={key}>
                                    <DivInput>
                                        <Label name={varProject[key].name} />
                                        <input id={varProject[key].id} className={varProject[key].type === "file" ? "fileClass" : "textClass"} type={varProject[key].type} required={varProject[key].required} accept={varProject[key].accept} name={varProject[key].type === "file" ? "files" : "texts"} onChange={varProject[key].type === "file" ? e => { handleFiles(e, files) }: handleInput} nb="0"/>
                                        <div id="divFiles" name="divFiles">
                                        </div>
                                    </DivInput>
                                </DivGroupInput>
                            )                        
                        }else{
                            tmp.push(
                                <DivGroupInput key={key}>
                                    <DivInput>
                                        <label>
                                            {varProject[key].name} 
                                            {varProject[key].value === "zone" ? 
                                            <a 
                                                id="schema"
                                                href="https://soelys.fr/wp-content/uploads/2022/03/normes-sortie-en-ventouse-poele-a-granules.png"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Schéma en détail
                                            </a> : `` }
                                        </label>
                                        <Select defaultValue={""} option={varProject[key].options} id={varProject[key].value} required={varProject[key].required} name="selects" onChange={varProject[key].value === "zone" ? (e => { handleSelectZone(e, varProject[key].regles, variable, files) }) : handleInput}/>
                                    </DivInput>
                                </DivGroupInput>
                            )
                        }
                    })
                    return tmp;
                })()}   

                <Submit>
                    <Input id="submit" type="submit" value="Suivant" />
                </Submit>
                
            </Form>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 25px;

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 1350px){
        margin-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;

        #back{
            font-size: 15px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: start;

    label{
        text-align: justify;
        margin-bottom: 1%;
        margin-top: 1%;
        font-size: 22px;
        font-weight: 700;

        a{
            margin-left: 2%;
            text-decoration-line: none ! important;
            font-size: 21px ! important;
        }
    }

    #messError{
        color: red;
    }

    #DivVar{
        div{
            padding: 0% 0.5%
        }

        @media screen and (max-width: 950px){
            select, input {
                font-size: 16px;
            }
        }

        @media screen and (max-width: 750px){
            select, input {
                font-size: 22px;
            }
        }
    }

    #priceTotal{
        margin: 0px 10px 0px auto;
        div{
            width: 35%;
        }
    }
`;

const DivInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2% 0%;
    width: 100%;

    @media screen and (max-width: 750px){
        width: auto;
    }

    input{
        border-radius: 5px;
        font-size : 18px;
        color: transparent;
        overflow: hidden;
    }

    .textClass{
        color: black;
    }

    select{
        font-size: 22px;
    }

    #divFiles{
        display: flex;
        margin-top: 2%;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    #divFile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 29%;
        padding: 1%;
        margin: 1%;
        position: relative;

        label{
            font-size: 20px;
            font-weight: 100;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
            text-align: center;
            position: absolute;
            bottom: 2%
        }

        a{
            position: absolute;
            top: 2%;
            left: 5%;
            font-weight: 700;
            color: red;
            font-size: 20px;
        }
    }

    #divReglesZone{
        display: flex;
        flex-direction: column;
        margin: 2% 0%;
        width: 100%;
        text-align: justify;

        div{
            margin: 1% 0%;
        }
    }

    #schema{
        font-size: 15px;
        font-weight: 100;
        text-decoration-line: underline;
    }
`;

const DivGroupInput = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    #selectVar{
        width: 65%;
        padding: 0% 1% 0% 0%;
    }

    #inputVar{
        padding: 0% 0% 0% 1%;
    }

    @media screen and (max-width: 750px){
        flex-direction: column;

        #selectVar{
            width: 100%;
        }
    }
`;

const Submit = styled.div`
    margin: 5% 0%;
    width: 100%;

    #submit{
        width: 50%;
        font-size: 18px;
        border-radius: 100px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 750px){
        #submit{
            font-size: 15px;
        }
    }
`;

export default FormAddFileProkject;