import React from "react";
import { Redirect } from "react-router";
import { AddFileAfterTemplate } from "../../components/templates";

const AddFileAfter = props => {
    const { projectId } = props.location.state;
    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404" />
    }else{
        return (
            <>
                <AddFileAfterTemplate projectId={ projectId }/>
            </>
        )
    }
}

export default AddFileAfter;