import React from "react";
import styled from "styled-components";
import { ProjectsOrga } from "../../organisms";

const ProjectsTemplate = ({ status, techId }) => {
    return (
        <Container>
            <ProjectsOrga status={ status } techId={ techId }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default ProjectsTemplate;