import React from "react";
import styled from "styled-components";
import { CompteOrga } from "../../organisms";

const CompteTemplate = ({ compteId }) => {
    return (
        <Container>
            <CompteOrga compteId={ compteId }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default CompteTemplate;