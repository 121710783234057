import React from "react";

const Iframe = (props) => {
    return(
        <>
            <iframe title={props.title} src={props.src} id={props.id} width={props.width} height={props.height}>{props.name}</iframe>
        </>
    );
}

export default Iframe;