import React from "react";
import styled from "styled-components";
import { FormNewCustomer } from "../../molecules";

const NewCustomerOrga = () => {

    return(
        <Container>
            <FormNewCustomer/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    height: inherit;
`;

export default NewCustomerOrga;