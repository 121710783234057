import React from "react";
import { Label } from "../../atoms";
import styled from "styled-components";

const Error = props => {

    return(
        <Container>
            <Label name={props.name}/>
        </Container>
    );
}

const Container = styled.div`
    label{
        font-size: 40px;
        color: black;
    }
`;

export default Error;