import React from "react";

const Canvas = (props) => {
    return(
        <>
            <canvas id={props.id} width={props.width} height={props.height}>{props.name}</canvas>
        </>
    );
}

export default Canvas;