import React, { useEffect } from "react";
import { Button, Canvas, Iframe, Input, Label} from "../../atoms";
import styled from "styled-components";
import axios from 'axios';

const FormSignature = ({ projectId }) => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [bufferDevis, setBufferDevis] = React.useState("");
    const [empty, setEmpty] = React.useState(true);
    const [messError, setMessError] = React.useState("");
    const [messValid, setMessValid] = React.useState("");

    useEffect(() => {
        const fetchCustomer = () => {
            setMessError("");
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                if(response.data.type !== "tech"){
                    window.alert("Vous n'êtes pas autorisé à effectuer cette action");
                    window.location = window.location.origin + "/404";
                }
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
            axios.get(process.env.REACT_APP_URL_API + 'project/pdf/' + projectId, {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                for(const e of response.data){
                    if(e.file === "devis"){
                        setBufferDevis(e.encode);
                    }
                }
            }).catch((error) => {
                if(error.response.status === 401){
                    var storage = window.localStorage;
                    storage.clear();
                    window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                    window.location = window.location.origin;
                }else{
                    setMessError(error.response.data.message);
                }
            });
        };

        fetchCustomer();
    }, [tokenHeader, projectId]);
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setMessError("");
        setMessValid("");
        if(empty){
            setMessError("Veuillez signez, s'il vous plaît.");
            window.scrollTo(0,0);
        }else{
            const canva = document.querySelector("#signature");
            const data = canva.toDataURL('image/png');
            axios.post(process.env.REACT_APP_URL_API + 'project/addSignature&File', {
                id: projectId,
                dataImg: data,
                devis: true
            }, {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                window.scrollTo(0,0);
                setMessValid("Veuillez patienter, merci.");
                setTimeout(() => {
                    window.location = window.location.origin + "/endSignatureProjectRedirect?id=" + projectId;
                }, 3000);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        window.scrollTo(0,0);
                        setMessError(error.response.data.message);
                    }
                }
            });
        }
    }

    const handleBack = () => {
        var history = window.history;
        history.back();
    }

    window.addEventListener("load", () => {
        const canva = document.querySelector("#signature");
        const ctx = canva.getContext("2d");

        canva.width = 700;
        canva.height = 150;
        var scaleX = canva.clientWidth / canva.width;
        var scaleY = canva.clientHeight / canva.height;

        let painting = false;

        const startPosition = (e) => {
            e.preventDefault();
            painting = true;
            setEmpty(false);
            draw(e);
        }

        const finishedPosition = () => {
            painting = false;
            ctx.beginPath();
        }

        const draw = (e) => {
            var x = 0;
            var y = 0;
            if(e.type === "touchmove" || e.type === "touchstart"){
                var offsetX = e.touches[0].pageX - e.touches[0].target.offsetLeft;
                var offsetY = e.touches[0].pageY - e.touches[0].target.offsetTop;
                x = offsetX / scaleX;
                y = offsetY / scaleY;
            }else{
                x = e.offsetX / scaleX; 
                y = e.offsetY / scaleY;
            }
            if(!painting) return;

            ctx.lineWidth = 2;
            ctx.lineCap = "round";

            ctx.lineTo(x, y);
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(x, y);
        }

        canva.addEventListener("mousedown", startPosition);
        canva.addEventListener("mouseup", finishedPosition);
        document.addEventListener("mouseup", finishedPosition);
        canva.addEventListener("mousemove", draw);
        canva.addEventListener("touchstart", startPosition);
        canva.addEventListener("touchend", finishedPosition);
        canva.addEventListener("touchmove", draw, true);
    })

    const handleCanvaDelete = (e) => {
        e.preventDefault();
        const canva = document.querySelector("#signature");
        const ctx = canva.getContext("2d");
        ctx.clearRect(0, 0, canva.width, canva.height);
        setEmpty(true);
    }

    return(
        <Container>
            <Button id="back" name="Retour" onClick={ handleBack }/>
            <Form onSubmit={handleSubmit}>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                { messValid !== "" ? <Label name={ messValid }/> : <></> }

                { bufferDevis !== "" ?
                    <>
                        <DivGroupInput id="divDevis">
                            <DivInput>
                                <Label name="Devis :"/>
                                <Iframe title={"Devis en PDF"} src={"data:application/pdf;base64," + bufferDevis + "#toolbar=0"} width="100%" height="100%" id="devis"/>
                            </DivInput>
                        </DivGroupInput>
                    </>
                    :
                    <></>
                }
                <DivGroupInput>
                    <DivInput>
                        <Label name='Date et signature :'/>
                        <Canvas id="signature"/>
                        <Button name="Supprimer" onClick={ handleCanvaDelete } id="deleteCanva"/>
                    </DivInput>
                </DivGroupInput>
                    

                <Submit>
                    <Input id="submit" type="submit" value="Valider"/>
                </Submit>
                
            </Form>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 25px;

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 1350px){
        margin-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;

        #back{
            font-size: 15px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: start;

    label{
        text-align: justify;
        margin-bottom: 1%;
        margin-top: 1%;
        font-size: 22px;
        font-weight: 700;
    }

    #messError{
        color: red;
    }

    #divDevis{
        height: 500px;
    }
`;

const DivInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2%;
    width: 100%;

    @media screen and (max-width: 750px){
        width: auto;
        height: inherit;
    }

    canvas{
        border: 2px solid black;
        background: white;
    }

    #deleteCanva{
        margin-top: 1%;
        width: 25%;
        align-self: end;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
        box-shadow: 10px 4px 8px black;
    }

    iframe{
        background: white;
        border: 0px;
    }
`;

const DivGroupInput = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 750px){
        flex-direction: column;
    }
`;

const Submit = styled.div`
    margin: 5% 0%;
    width: 100%;

    #submit{
        width: 50%;
        font-size: 18px;
        border-radius: 100px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 750px){
        #submit{
            font-size: 15px;
        }
    }
`;

export default FormSignature;