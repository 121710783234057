import React from "react";
import { Redirect } from "react-router";
import { ChoiceCustomerTemplate } from "../../components/templates";

const ChoiceCustomer = () => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404" />
    }else{
        return (
            <>
                <ChoiceCustomerTemplate/>
            </>
        )
    }
}

export default ChoiceCustomer;