import React from "react";
import { HomeConnexOrga } from "../../organisms";
import styled from "styled-components";

const HomeConnexTemplate = ({ setTokenHeader }) => {
    return (
        <Container id="homeConnex">
            <HomeConnexOrga setTokenHeader={ setTokenHeader } />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    height: inherit;
`;

export default HomeConnexTemplate;