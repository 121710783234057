import axios from "axios";
import React, { useEffect } from "react";
import { Redirect } from "react-router";

const ProjectsRedirect = () => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [typeUser, setTypeUser] = React.useState("");

    useEffect(() => {
        const fetchCustomer = async () => {
            await axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                setTypeUser(response.data.type);
            }).catch((error) => {
                if(error.response.status === 401){
                    var storage = window.localStorage;
                    storage.clear();
                    window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                    window.location = window.location.origin;
                }
            });
        };

        fetchCustomer();
    }, [tokenHeader]);

    let status = "";
    if(typeUser === "admin"){
        status = "finish";
    }
    const tId = window.location.search.split("=")[1];
    if(typeUser !== ""){
        return (
            <Redirect to={{
            pathname: "/projects",
            state: {
                techId: tId,
                status: status
            }
        }}/>)
    }else{
        return(
            <></>
        )
    }
}

export default ProjectsRedirect;