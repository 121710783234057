import React, { useEffect } from "react";
import styled from "styled-components";
import axios from 'axios';
import { Ahref, Button, Label, Select, Input, Table } from "../../atoms";

const ListProject = ({ status, techId }) => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [typeUser, setTypeUser] = React.useState("");
    const [projects, setProjects] = React.useState([]);
    const [listProjetsTab, setListProjectsTab] = React.useState([]);
    const [listProjectSelect, setListProjectSelect] = React.useState([]);
    const [enCours, setEnCours] = React.useState(false);
    const [enCreation, setEnCreation] = React.useState(false);
    const [annule, setAnnule] = React.useState(false);
    const [toComplete, setToComplete] = React.useState(false);
    const [messError, setMessError] = React.useState("");

    const handleCheckBox = React.useCallback((event, projectsList) => {
        setEnCours(false);
        setEnCreation(false);
        setToComplete(false);
        setMessError("");
        let list = [];
        let error = false;
        var checkboxes = document.getElementsByName("checkboxProject");
        checkboxes.forEach((element) => {
            if(element !== event.target){
                if(element.checked){
                    projectsList.map(e => {
                        if(e.id === parseInt(element.id)){
                            list.push(e);
                        }
                        return true;
                    });
                }
            }
        });
        projectsList.map(e => {
            if(e.id === parseInt(event.target.id)){
                list.forEach((pro) => {
                    if(pro.status !== e.status){
                        event.target.checked = false;
                        setMessError("Vous devez selectionner des projects avec le même statut.");
                        error = true;
                    }else{
                        if(e.status === "Création"){
                            event.target.checked = false;
                            setMessError("Veuillez selectionner un unique projet pour ce statut.");
                            error = true;
                        }else if(e.status === "À compléter"){
                            event.target.checked = false;
                            setMessError("Veuillez selectionner un unique projet pour ce statut.");
                            error = true;
                        }
                    }
                })
                if(!error && event.target.checked){
                    list.push(e);
                    if(e.status === "En cours"){
                        setEnCours(true);
                    }else if(e.status === "Création"){
                        setEnCreation(true);
                    }else if(e.status === "À compléter"){
                        setToComplete(true);
                    }
                }
            }
            return true;
        });
        setListProjectSelect(list);
    }, [])

    const handleClickProject = React.useCallback((event, projectsList) => {
        var parent = event.target.parentNode;
        var checkboxes = document.getElementsByName("checkboxProject");
        checkboxes.forEach((element) => {
            if(element.id === parent.id){
                if(element.checked){
                    element.checked = false
                }else{
                    element.checked = true;
                }
                element.addEventListener("change", (e) => { handleCheckBox(e, projectsList) });
                var evt = new Event('change', { "bubbles": true });
                element.dispatchEvent(evt);
            };
        });
    }, [handleCheckBox])
    
    const changeProject = React.useCallback((e, projectsList) => {
        const result = {};

        let email = e.customer.email;
        email = email.split("@");
        email = email[0] + "\n@" + email[1];
        result.id = e.id;
        result.name = e.customer.lastname + " " + e.customer.firstname;
        result.address = e.customer.address;
        result.cp = e.customer.pc;
        result.city = e.customer.city;
        result.email = email;
        result.portable = e.customer.telPortable;
        result.project = e.name;
        result.status = e.status;
        result.statusMPR = e.statusMPR;
        result.statusInstall = e.statusInstallation;
        result.date = e.date;
        result.onClick = (e) => { handleClickProject(e, projectsList) };
        result.onChangeCheckbox = (e) => { handleCheckBox(e, projectsList) };

        if(e.status === "À compléter"){
            result.className = "ToComplete";
        }
        
        return result;
    }, [handleCheckBox, handleClickProject])

    useEffect(() => {
        const fetchCustomer = () => {
            setMessError("");
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                },
                decompress: true
            }).then((response) => {
                setTypeUser(response.data.type);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
            if(status){
                axios.get(process.env.REACT_APP_URL_API + `project/all/${techId}/${status}`, {
                    headers : {
                        'Authorization': tokenHeader
                    },
                    decompress: true
                }).then((response) => {
                    const listProject = [];
                    response.data.map(e => {
                        const result = changeProject(e, response.data);
                        listProject.push(result);
                        return listProject;
                    });
                    setProjects(response.data);
                    setListProjectsTab(listProject);
                }).catch((error) => {
                    if(error.response){
                        if(error.response.status){
                            setMessError(error.response.data.message);
                        }else{
                            window.alert("Une erreur est survenue.");
                            window.location = window.location.origin + "/404";
                        }
                    }
                });
            }else{
                axios.get(process.env.REACT_APP_URL_API + `project/all/${techId}/project`, {
                    headers : {
                        'Authorization': tokenHeader
                    }
                }).then((response) => {
                    const listProjects = [];
                    response.data.map(e => {
                        const result = changeProject(e, response.data);
                        listProjects.push(result);
                        return true;
                    });
                    setProjects(response.data);
                    setListProjectsTab(listProjects);
                }).catch((error) => {
                    if(error.response){
                        if(error.response.status){
                            setMessError(error.response.data.message);
                        }else{
                            window.alert("Une erreur est survenue.");
                            window.location = window.location.origin + "/404";
                        }
                    }
                });
            }
        };

        fetchCustomer();
    }, [tokenHeader, status, techId, changeProject]);

    const sortTable = (e) => {
        var rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        var table = document.getElementById("myTable");
        const th = e.target;
        var n = th.id;
        let dat = 0;
        let day, mouth = "";

        if(table){
            switching = true;
            dat = false;
            dir = "asc"; 
            while (switching) {
                switching = false;
                rows = table.rows;
                for (i = 1; i < (rows.length - 1); i++) {
                    shouldSwitch = false;
                    x = rows[i].getElementsByTagName("TD")[n];
                    if(x.id === "date"){
                        dat = true;
                    }
                    y = rows[i + 1].getElementsByTagName("TD")[n];
                    if (dir === "asc") {
                        if(dat){
                            let tmpA = x.innerHTML.split("/");
                            day = tmpA[0];
                            mouth = tmpA[1];
                            tmpA[0] = mouth;
                            tmpA[1] = day;
                            tmpA = tmpA.join("/");
                            let dateA = new Date(tmpA);
                            let tmpB = y.innerHTML.split("/");
                            day = tmpB[0];
                            mouth = tmpB[1];
                            tmpB[0] = mouth;
                            tmpB[1] = day;
                            tmpB = tmpB.join("/");
                            let dateB = new Date(tmpB);
                            if (dateA > dateB) {
                                shouldSwitch = true;
                                break;
                            }
                        }else{
                            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                                shouldSwitch = true;
                                break;
                            }
                        }
                    } else if (dir === "desc") {
                        if(dat){
                            let tmpA = x.innerHTML.split("/");
                            day = tmpA[0];
                            mouth = tmpA[1];
                            tmpA[0] = mouth;
                            tmpA[1] = day;
                            tmpA = tmpA.join("/");
                            let dateA = new Date(tmpA);
                            let tmpB = y.innerHTML.split("/");
                            day = tmpB[0];
                            mouth = tmpB[1];
                            tmpB[0] = mouth;
                            tmpB[1] = day;
                            tmpB = tmpB.join("/");
                            let dateB = new Date(tmpB);
                            if (dateA < dateB)  {
                                shouldSwitch = true;
                                break;
                            }
                        }else{
                            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                                shouldSwitch = true;
                                break;
                            }
                        }
                    }
                }
                if (shouldSwitch) {
                    rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                    switchcount ++;
                } else {
                    if (switchcount === 0 && dir === "asc") {
                        dir = "desc";
                        switching = true;
                    }
                }
            }
        }
    }

    const thead = [
        {
            id: 0,
            name: " "
        },
        {
            id: 1,
            name: "Nom"
        },
        {
            id: 2,
            name: "Adresse"
        },
        {
            id: 3,
            name: "Code postal"
        },
        {
            id: 4,
            name: "Ville"
        },
        {
            id: 5,
            name: "Email"
        },
        {
            id: 6,
            name: "Téléphone"
        },
        {
            id: 7,
            name: "Projet"
        },
        {
            id: 8,
            name: "Etat"
        },
        {
            id: 9,
            name: "Status MPR"
        },
        {
            id: 10,
            name: "Status Installation"
        },
        {
            id: 11,
            name: "Date"
        }
    ];

    const optionEtat = [
        {
            id: 1,
            value: "all",
            name: "Tous les projects"
        },
        {
            id: 2,
            value: "Nouveau",
            name: "Nouveau"
        },
        {
            id: 3,
            value: "Création",
            name: "Création"
        },
        {
            id: 4,
            value: "En cours",
            name: "En cours"
        },
        {
            id: 5,
            value: "Annule",
            name: "Annuler"
        },
        {
            id: 6,
            value: "À compléter",
            name: "À compléter"
        },
        {
            id: 7,
            value: "Finaliser",
            name: "Finaliser"
        },
        {
            id: 8,
            value: "Supprimer",
            name: "Supprimer"
        }
    ]

    const optionMoveTo = [
        {
            id: 1,
            value: " ",
            name: "Choisissez une valeur",
            disabled: true
        },
        {
            id: 2,
            value: "En cours",
            name: "En cours"
        },
        {
            id: 3,
            value: "Annule",
            name: "Annuler"
        },
        {
            id: 4,
            value: "Finaliser",
            name: "Finaliser"
        }
    ]

    const optionCause = [
        {
            id: 1,
            value: " ",
            name: "Choisissez une valeur",
            disabled: true
        },
        {
            id: 2,
            value: "Client",
            name: "Client"
        },
        {
            id: 3,
            value: "Technique",
            name: "Technique"
        },
        {
            id: 4,
            value: "MPR",
            name: "Refus MPR"
        }
    ]

    const handleBack = () => {
        window.location = window.location.origin;
    }

    const handleEtatProjet = (e) => {
        setEnCours(false);
        setEnCreation(false);
        setToComplete(false);
        setMessError("");
        const value = e.target.selectedOptions[0].value;
        var listProjects = [];
        if(value === "all"){
            projects.map(p => {
                const result = changeProject(p, projects);
                listProjects.push(result);
                return true;
            });
        }else if(value === "Annule"){
            let tabValid = ["Annulation client", "Annulation technique", "Annulation refus MPR"];
            projects.map(p => {
                Object.keys(p).forEach((key) => {
                    if(key === "status"){
                        if(tabValid.includes(p[key])){
                            const result = changeProject(p, projects);
                            listProjects.push(result);
                        }
                    }
                })
                return true;
            });
        }else{
            projects.map(p => {
                Object.keys(p).forEach((key) => {
                    if(key === "status"){
                        if(p[key] === value){
                            const result = changeProject(p, projects);
                            listProjects.push(result);
                        }
                    }
                })
                return true;
            })
        }
        setListProjectsTab(listProjects);
    }

    const handleSearch = (e) => {
        const value = e.target.value;
        let result = [];
        let list = projects.filter(function(p){
            let exist = false;
            let lastname = p.customer.lastname.toLowerCase();
            let firstname = p.customer.firstname.toLowerCase();
            let address = p.customer.address.toLowerCase();
            let cp = p.customer.pc.toLowerCase();
            let city = p.customer.city.toLowerCase();
            if(lastname.indexOf(value.toLowerCase()) !== -1){
                exist = true;
            }
            if(firstname.indexOf(value.toLowerCase()) !== -1){
                exist = true;
            }
            if(address.indexOf(value.toLowerCase()) !== -1){
                exist = true;
            }
            if(cp.indexOf(value.toLowerCase()) !== -1){
                exist = true;
            }
            if(city.indexOf(value.toLowerCase()) !== -1){
                exist = true;
            }
            return exist;
        })
        for(let l of list){
            let r = changeProject(l, projects);
            result.push(r);
        }
        setListProjectsTab(result);
    }

    const handleDownload = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        if(listProjectSelect.length !== 0){
            checked = true;
        }
        if(!checked){
            setMessError("Veuillez cochez au moins une ligne du tableau s'il vous plait.");
            return false;
        }
        axios.patch(process.env.REACT_APP_URL_API + `project/files`, {
            listProjects: listProjectSelect
        },{
            headers : {
                'Authorization': tokenHeader
            }
        }).then((response) => {
            const zipFolder = response.data;
            let bufferFile = zipFolder.base64;
            var contentType = 'application/zip';
            var sliceSize = 1024;
            const bufferBase64 = Buffer.from(bufferFile, "base64");
            const byteCharacters = bufferBase64.toString("latin1");
            var bytesLength = byteCharacters.length;
            var slicesCount = Math.ceil(bytesLength / sliceSize);
            var byteArrays = new Array(slicesCount);

            for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                var begin = sliceIndex * sliceSize;
                var end = Math.min(begin + sliceSize, bytesLength);

                var bytes = new Array(end - begin);
                for (var offset = begin, i = 0 ; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            var blob = new Blob(byteArrays, { type: contentType });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob, { type: "application/zip" });
            link.download = zipFolder.folderName + ".zip";
            link.click();
        }).catch((error) => {
            window.alert("Le dossier n'a pas pu être télécharger, veuillez réessaye ultérieurement.");
        });
    }

    const handleMoveTo = (event) => {
        event.preventDefault();
        setMessError("");
        setAnnule(false);
        var checked = false;
        const value = event.target.selectedOptions[0].value;
        if(listProjectSelect.length !== 0){
            checked = true;
        }
        if(!checked){
            setMessError("Veuillez cochez au moins une ligne du tableau s'il vous plait.");
            const select = document.getElementById("selectMoveTo");
            select.value = " ";
            return false;
        }
        if(value !== "Annule"){
            let succes = false;
            listProjectSelect.forEach((project) => {
                projects.map(e => {
                    if(e.id === parseInt(project.id)){
                        axios.patch(process.env.REACT_APP_URL_API + `project/${e.id}`, {
                            status: value
                        }, {
                            headers : {
                                'Authorization': tokenHeader
                            } 
                        }).then((response) => {
                            succes = true
                        }).catch((error) => {
                            if(error.response){
                                window.alert("Une erreur est survenue.");
                                window.location = window.location.origin;
                            }
                        });
                    }
                    return false;
                });
            })
            if(succes){
                window.location.reload();
            }
        }else{
            setAnnule(true);
        }
    }

    const handleCause = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        var result = "";
        const value = event.target.selectedOptions[0].value;
        if(listProjectSelect.length !== 0){
            checked = true;
        }
        if(!checked){
            setAnnule(false);
            setMessError("Veuillez cochez au moins une ligne du tableau s'il vous plait.");
            const select = document.getElementById("selectMoveTo");
            select.value = " ";
            return false;
        }
        switch(value){
            case "Client":
                result = "Annulation client";
                break;
            case "Technique":
                result = "Annulation technique";
                break;
            case "MPR":
                result = "Annulation refus MPR";
                break
            default:
                setMessError("La cause de l'annulation n'existe pas.");
                setAnnule(false);
                const select = document.getElementById("selectMoveTo");
                select.value = " ";
                return false;
        }
        let succes = false;
        listProjectSelect.forEach((project) => {
            projects.map(e => {
                if(e.id === parseInt(project.id)){
                    axios.patch(process.env.REACT_APP_URL_API + `project/${e.id}`, {
                        status: result
                    }, {
                        headers : {
                            'Authorization': tokenHeader
                        } 
                    }).then((response) => {
                        succes = true
                    }).catch((error) => {
                        if(error.response){
                            window.alert("Une erreur est survenue.");
                            window.location = window.location.origin;
                        }
                    });
                }
                return false;
            });
        })
        if(succes){
            window.location.reload();
        }
    }

    const handleEnCreation = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        var idProjet = 0;
        var idCustomer = 0;
        if(listProjectSelect.length > 1){
            setMessError("Veuillez cochez une seule ligne du tableau s'il vous plait.");
            return false;
        }
        listProjectSelect.forEach((project) => {
            projects.map(e => {
                if(e.id === parseInt(project.id)){
                    checked = true;
                    idProjet = parseInt(project.id);
                    idCustomer = e.customer.id;
                }
                return false;
            });
        })
        if(!checked){
            setMessError("Veuillez cochez une ligne du tableau s'il vous plait.");
            const select = document.getElementById("selectMoveTo");
            select.value = " ";
            return false;
        }
        window.location = window.location.origin + "/newProjectRedirect?cid=" + idCustomer + "&pId=" + idProjet;
    }

    const handleTechnicien = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        var idTech = 0;
        if(listProjectSelect.length > 1){
            setMessError("Veuillez cochez une seule ligne du tableau s'il vous plait.");
            return false;
        }
        listProjectSelect.forEach((project) => {
            projects.map(e => {
                if(e.id === parseInt(project.id)){
                    checked = true;
                    idTech = e.tech.id;
                }
                return false;
            });
        })
        if(!checked){
            setMessError("Veuillez cochez une ligne du tableau s'il vous plait.");
            return false;
        }
        window.location = window.location.origin + `/compteRedirect?id=${idTech}`;
    }

    const handleFacture = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        var idProjet = 0;
        if(listProjectSelect.length > 1){
            setMessError("Veuillez cochez une seule ligne du tableau s'il vous plait.");
            return false;
        }
        listProjectSelect.forEach((project) => {
            projects.map(e => {
                if(e.id === parseInt(project.id)){
                    checked = true;
                    idProjet = e.id;
                }
                return false;
            });
        })
        if(!checked){
            setMessError("Veuillez cochez une ligne du tableau s'il vous plait.");
            return false;
        }
        window.location = window.location.origin + "/newFactureRedirect?pid=" + idProjet;
    }

    const handleValidate = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        if(listProjectSelect.length !== 0){
            checked = true;
        }
        if(!checked){
            setMessError("Veuillez cochez au moins une ligne du tableau s'il vous plait.");
            return false;
        }
        let text = "";
        if(listProjectSelect.length === 1){
            text = "Êtes vous sûr de vouloir valider ce projet ?";
        }else{
            text = "Êtes vous sûr de vouloir valider ces projets ?";
        }
        const confirm = window.confirm(text);
        if(confirm === true){
            let succes = false;
            listProjectSelect.forEach((project) => {
                projects.map(e => {
                    if(e.id === parseInt(project.id)){
                        axios.patch(process.env.REACT_APP_URL_API + `project/${e.id}`, {
                            status: "Finaliser"
                        }, {
                            headers : {
                                'Authorization': tokenHeader
                            } 
                        }).then((response) => {
                            succes = true;
                        }).catch((error) => {
                            window.alert("Une erreur est survenue, veuillez réessaye ultérieurement.");
                        });
                    }
                    return false;
                });
            })
            if(succes){
                window.location.reload();
            }
        }
    }

    const handleRefuse = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        var idProjet = 0;
        if(listProjectSelect.length > 1){
            setMessError("Veuillez cochez une seule ligne du tableau s'il vous plait.");
            return false;
        }
        listProjectSelect.forEach((project) => {
            projects.map(e => {
                if(e.id === parseInt(project.id)){
                    checked = true;
                    idProjet = e.id;
                }
                return false;
            });
        })
        if(!checked){
            setMessError("Veuillez cochez une ligne du tableau s'il vous plait.");
            return false;
        }
        const text = "Saississez en détail les éléments manquant.";
        const prompt = window.prompt(text);
        if(prompt !== null){
            if(prompt !== ""){
                axios.patch(process.env.REACT_APP_URL_API + `project/${idProjet}`, {
                    status: "À compléter",
                    commentary: prompt
                }, {
                    headers : {
                        'Authorization': tokenHeader
                    } 
                }).then((response) => {
                    window.location.reload();
                }).catch((error) => {
                    window.alert("Une erreur est survenue.");
                });
            }else{
                window.alert("Vous n'avez pas indiquez les éléments manquant.");
            }
        }
    }

    const handleChangeMPR = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        var idProjet = 0;
        if(listProjectSelect.length > 1){
            setMessError("Veuillez cochez une seule ligne du tableau s'il vous plait.");
            return false;
        }
        listProjectSelect.forEach((project) => {
            projects.map(e => {
                if(e.id === parseInt(project.id)){
                    checked = true;
                    idProjet = e.id;
                }
                return false;
            });
        })
        if(!checked){
            setMessError("Veuillez cochez une ligne du tableau s'il vous plait.");
            return false;
        }
        window.location = window.location.origin + `/statusMPRRedirect?id=${idProjet}`;
    }

    const handleAjout = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        var idProjet = 0;
        if(listProjectSelect.length > 1){
            setMessError("Veuillez cochez une seule ligne du tableau s'il vous plait.");
            return false;
        }
        listProjectSelect.forEach((project) => {
            projects.map(e => {
                if(e.id === parseInt(project.id)){
                    checked = true;
                    idProjet = e.id;
                }
                return false;
            });
        })
        if(!checked){
            setMessError("Veuillez cochez une ligne du tableau s'il vous plait.");
            return false;
        }
        window.location = window.location.origin + `/addFileAfterRedirect?id=${idProjet}`;
    }

    const handleDelete = (event) => {
        event.preventDefault();
        setMessError("");
        var checked = false;
        if(listProjectSelect.length !== 0){
            checked = true;
        }
        if(!checked){
            window.scrollTo(0,0);
            setMessError("Veuillez cochez une ligne du tableau s'il vous plait.");
            const select = document.getElementById("selectMoveTo");
            select.value = " ";
            return false;
        }
        let succes = false;
        listProjectSelect.forEach((project) => {
            projects.map(e => {
                if(e.id === parseInt(project.id)){
                    axios.patch(process.env.REACT_APP_URL_API + `project/${e.id}`, {
                        status: "Supprimer"
                    }, {
                        headers : {
                            'Authorization': tokenHeader
                        } 
                    }).then((response) => {
                        window.location.reload();
                    }).catch((error) => {
                        if(error.response){
                            window.alert("Une erreur est survenue.");
                            window.location = window.location.origin;
                        }
                    });
                }
                return false;
            });
        })
        if(succes){
            window.location.reload();
        }
    }

    window.addEventListener("scroll", function() {
        let tabHeader = document.getElementById("tabDivHead");
        if (window.pageYOffset > 0) {
            var divOffsetTop = document.getElementById("divBody").offsetTop;
            let divHeader = document.getElementById("divHeader");
            let opac = window.pageYOffset / 0.75 / divOffsetTop;
            let top = 6 - window.pageYOffset / 0.35 / divOffsetTop;
            if(opac > 2){
                opac = 2;
            }
            if(top < 0){
                top = 0;
            }
            divHeader.style.backgroundImage = `radial-gradient(circle, rgba(1,51,254,${opac}) 0%, rgba(251,254,0,${opac}) 100%)`;
            divHeader.style.paddingTop = `${opac + 1}%`;
            divHeader.style.top = `${top}%`;
            tabHeader.style.display = "block";
            tabHeader.style.opacity = `${opac}`;
        }else{
            tabHeader.style.display = "none";
        }
    });

    thead.map(e => {
        const thDivHead = document.getElementsByName("thDivHead" + e.id);
        const thDivBody = document.getElementsByTagName("th");
        if(thDivBody){
            if(listProjetsTab.length !== 0){
                setTimeout(() => {
                    for(let tb of thDivBody){
                        if(parseInt(tb.id) === e.id){
                            thDivHead[0].style.width = `${tb.offsetWidth - 1}px`;
                            thDivHead[0].style.height = `44px`;
                        }
                    }
                }, 1000)
            }
        }
        return true;
    })

    return(
        <Container>
            <DivHeader id="divHeader">
                <Button id="back" name="Retour" onClick={ handleBack }/>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                <DivAction>
                    { !status ? 
                        <Select defaultValue={ "all" } option={ optionEtat } id="etatProjet" name="etatProjet" onChange={ handleEtatProjet } /> 
                        : 
                        <></>
                    }
                    <DivSearch>
                        <Label id="search" name="Rechercher : "/>
                        <Input id="search" type="text" name="search" onChange={ handleSearch }/>
                    </DivSearch>
                </DivAction>
                <DivAction>
                    { enCreation ? 
                        <Ahref href="#" name="Continuer le projet" onClick={ handleEnCreation } />
                        :
                        <Ahref href="#" name="Téléchargement" onClick={ handleDownload } />
                    }
                    { toComplete ? 
                        <Ahref href="#" name="Ajouter des fichiers" onClick={ handleAjout } />
                        :
                        <></>
                    }
                    { typeUser === "admin" ?
                        <>
                            <DivSelect>
                                <Label id="moveTo" name="Déplacer vers :" />
                                <Select defaultValue={" "} id="selectMoveTo" option={ optionMoveTo } name="selectMoveTo" onChange={ handleMoveTo } />
                            </DivSelect>
                            { annule ? 
                                <DivSelect>
                                    <Label id="cause" name="Cause :" />
                                    <Select defaultValue={" "} id="selectCause" option={ optionCause } name="selectCause" onChange={ handleCause } />
                                </DivSelect>
                                :
                                <></>
                            }
                        </>
                        :
                        <></>
                    }
                </DivAction>
                { typeUser === "admin" ? 
                    <DivAction>
                        <Ahref href="#" name="Facture" onClick={ handleFacture }/>
                        <Ahref href="#" name="Technicien" onClick={ handleTechnicien }/>
                        { enCours ? 
                            <>
                                <Ahref href="#" name="Valider" onClick={ handleValidate }/>
                                <Ahref href="#" name="Refuser" onClick={ handleRefuse }/>
                            </>
                            :
                            <></>
                        }
                        <Ahref href="#" name="Modifier Status MPR" onClick={ handleChangeMPR }/>
                        <Ahref href="#" name="Supprimer" onClick={ handleDelete }/>
                    </DivAction>
                    :
                    <></>
                }
                { listProjetsTab.length === 0 ? 
                    <>
                        { typeUser === "admin" ? 
                            <>
                                { techId !== "0" ? 
                                    <Label id="messPasProject" name="Cette utilisateur n'a pas de dossier dans cette catégorie pour l'instant."/>
                                    :
                                    <Label id="messPasProject" name="Il n'y a pas de dossier dans cette catégorie pour l'instant."/>
                                }
                            </>
                            :
                            <Label id="messPasProject" name="Vous n'avez pas de dossier dans cette catégorie."/>
                        }
                    </>
                    :
                    <></>
                }
                <table id="tabDivHead">
                    <thead>
                        <tr>
                            {thead.map(e => {
                                return(
                                    <th onClick={sortTable} key={e.id} id={e.id} name={"thDivHead" + e.id}>{e.name}</th>
                                )
                            })}
                        </tr>
                    </thead>
                </table>
            </DivHeader>
            <DivBody id="divBody">
                <Table onClick={sortTable} thead={ thead } tbody={ listProjetsTab }/>
            </DivBody>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1% 0% 0% 0%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 15px;
    font-size: 20px;

    label{
        text-align: justify;
        margin-bottom: 1%;
        margin-top: 1%;
        font-size: 22px;
        font-weight: 700;
    }

    #messError{
        margin-left: 1%;
        color: red;
    }

    #messPasProject{
        margin-left: 10px;
    }

    #back{
        width: 15%;
        margin: 0px 10px 0px auto;
        font-size: 15px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }

    #etatProjet{
        font-size: 20px;
        padding: 2px 2%;
        width: 50%;
    }

    @media screen and (max-width: 1350px){
        td, th {
            font-size: 15px;
        }
    }

    @media screen and (max-width: 950px){
        td, th {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 750px){
        font-size: 15px;

        #back{
            font-size: 15px;
        }

        td, th {
            font-size: 10px;
        }
    }

    table{
        width: 100%;
        margin: 3% 0%;
    }

    th {
        background-color: rgb(0, 0, 0, 0.1);
        cursor: pointer;
    }

    td, th {
        border: 1px solid black;
        
    }

    td {
        padding: 0.5% 0%;
        width: 1%;
        background: white;
    }

    .ToComplete{
        color: red;
        font-weight: 800;
    }
`;

const DivAction = styled.div`
    display: flex;
    margin: 20px 0px;
    justify-content: space-evenly;
    width: 100%;

    label{
        margin-bottom: 0%;
        margin-top: 0%;
        font-weight: 400;
    }

    @media screen and (max-width: 900px){
        label{
            font-size: 15px;
        }
    }
`;

const DivSelect = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 35%;
`;

const DivHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 95%;
    flex: 1;
    position: fixed;
    padding: 1% 0% 0% 0%;
    border-radius: 15px;

    #tabDivHead{
        margin: 1% 0%;
        display: none;
    }
`;

const DivBody = styled.div`
    margin-top: 20%;
    width: 100%;
`;

const DivSearch = styled.div`
    display: flex;
    align-items: center;

    input{
        margin-left: 10px;
    }
`;

export default ListProject;