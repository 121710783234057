import React, { useEffect } from "react";
import { Button, Input, Label, Select } from "../../atoms";
import styled from "styled-components";
import axios from 'axios';

const InfoCompte = ({ compteId }) => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [typeUser, setTypeUser] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [verifyPassword, setVerifyPassword] = React.useState("");
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [type, setType] = React.useState("");
    const [typeAdmin, setTypeAdmin] = React.useState(false);
    const [emailRegie, setEmailRegie] = React.useState("");
    const [disable, setDisable] = React.useState(true);
    const [checked, setChecked] = React.useState(false);
    const [exist, setExist] = React.useState(true);
    const [optionRegie, setOptionRegie] = React.useState([]);
    const [messError, setMessError] = React.useState("");
    const [messValid, setMessValid] = React.useState("");

    useEffect(() => {
        const fetchCustomer = () => {
            setMessError("");
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                setTypeUser(response.data.type);
                if(response.data.type === "admin"){
                    setDisable(false);
                }
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError("error.response.data.message");
                    }
                }
            });

            axios.get(process.env.REACT_APP_URL_API + 'user/regieandtech/false', {
                headers: { 
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                let regie = [{
                    id: 0,
                    value: "DEFAULT",
                    name: "Choisissez l'email de la régie gérante du technicien.",
                    disabled: true,
                    hidden: true
                },{
                    id: 1,
                    value: "NotEmailRegie",
                    name: "Le technicien n'est pas sous la resposabilité d'une régie.",
                    disabled: true,
                    hidden: true
                }];
                for(const res of response.data){
                    const tmp = {
                        id: res.id,
                        value: res.email,
                        name: res.email
                    }
                    regie.push(tmp);
                }
                setOptionRegie(regie);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status){
                        setMessError("error.response.data.message");
                    }else{
                        window.alert("Une erreur est survenue.");
                        window.location = window.location.origin + "/404";
                    }
                }
            });

            axios.get(process.env.REACT_APP_URL_API + 'user/' + compteId, {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                setEmail(response.data.email);
                setPassword("*******");
                setLastname(response.data.lastname);
                setFirstname(response.data.firstname);
                setType(response.data.type);
                setEmailRegie(response.data.emailRegie);

                const selectType = document.getElementById("type");

                if(response.data.type === "admin"){
                    selectType.disabled = true;
                    selectType.innerHTML = selectType.innerHTML + `
                        <option value="admin">Admin</option>
                    `;
                    setTypeAdmin(true);
                }

                selectType.value = response.data.type;
                
                if(response.data.emailRegie === undefined){
                    if(response.data.type === "tech"){
                        const selectEmailRegie = document.getElementById("emailRegie");
                        selectEmailRegie.value = "NotEmailRegie";
                    }
                }
            }).catch((error) => {
                if(error.response){
                    if(error.response.status){
                        setMessError("error.response.data.message");
                        if(error.response.status === 409){
                            setExist(false);
                        }
                    }else{
                        window.alert("Une erreur est survenue.");
                        window.location = window.location.origin + "/404";
                    }
                }
            });
        };

        fetchCustomer();
    }, [tokenHeader, compteId]);

    const strUcFirst = (string) => {
        const str = string.toLowerCase();
        return (str+'').charAt(0).toUpperCase()+str.substr(1);
    }

    const strLc = (string) => {
        return string.toLowerCase();
    }

    const handleEmail = (event) => {
        const emailTarget = strLc(event.target.value);
        setEmail(emailTarget);
    }
    
    const handlePassword = (event) => { 
        setPassword(event.target.value);
    }

    const handleVerifyPassword = (event) => {
        setVerifyPassword(event.target.value);
    }

    const handleFirstname = (event) => { 
        const firstnameTarget = strUcFirst(event.target.value);
        setFirstname(firstnameTarget);
    }

    const handleLastname = (event) => {
        const lastnameTarget = strUcFirst(event.target.value);
        setLastname(lastnameTarget);
    }

    const handleType = (event) => {
        setType(event.target.value);
        setTimeout(() => {
            if(event.target.value === "tech"){
                if(emailRegie === undefined){
                    const selectEmailRegie = document.getElementById("emailRegie");
                    selectEmailRegie.value = "NotEmailRegie";
                }
            }
        }, 10);
    }

    const handleEmailRegie = (event) => {
        setEmailRegie(event.target.value);
    }

    const selectType = [
        {
            id: 1,
            value: "DEFAULT",
            name: "Choisissez le type de l'utilisateur",
            disabled: true
        },
        {
            id: 2,
            value: "tech",
            name: "Technicien"
        },
        {
            id: 3,
            value: "regie",
            name: "Régie"
        }
    ]

    const handleSubmit = (event) => {
        event.preventDefault();
        setMessError("");
        setMessValid("");
        if(emailRegie === undefined){
            setEmailRegie(undefined);
        }
        if(type !== "tech"){
            setEmailRegie(undefined);
        }
        if(checked){
            if(password === verifyPassword){
                axios.patch(process.env.REACT_APP_URL_API + 'user/' + compteId, {
                    password,
                    lastname,
                    firstname,
                    type,
                    emailRegie
                }, {
                    headers: { 
                        'Authorization': tokenHeader
                    }
                }).then((response) => {
                    setMessValid(`L'utilisateur ${response.data.lastname} ${response.data.firstname} a bien été modifier. Attendez 5 secondes, vous allez être rediriger.`);
                    setTimeout(() => {
                        window.location = window.location.origin
                    }, 5000);
                }).catch((error) => {
                    if(error.response){
                        if(error.response.status === 401){
                            window.alert("Vous n'êtes pas autorisé à effectuer cette action.");
                            window.location = window.location.origin;
                        }else{
                            setMessError(error.response.data.message);
                        }
                    }
                });
            }else{
                setMessError("Les mot de passe saissie ne sont pas identique.");
            }
        }else{
            if(typeAdmin){
                axios.patch(process.env.REACT_APP_URL_API + 'user/' + compteId, {
                    lastname,
                    firstname
                }, {
                    headers: { 
                        'Authorization': tokenHeader
                    }
                }).then((response) => {
                    setMessValid(`L'utilisateur ${response.data.lastname} ${response.data.firstname} a bien été modifier. Attendez 5 secondes, vous allez être rediriger.`);
                    setTimeout(() => {
                        window.location = window.location.origin
                    }, 5000);
                }).catch((error) => {
                    if(error.response){
                        if(error.response.status === 401){
                            window.alert("Vous n'êtes pas autorisé à effectuer cette action.");
                            window.location = window.location.origin;
                        }else{
                            setMessError(error.response.data.message);
                        }
                    }
                });
            }else{
                axios.patch(process.env.REACT_APP_URL_API + 'user/' + compteId, {
                    lastname,
                    firstname,
                    type,
                    emailRegie
                }, {
                    headers: { 
                        'Authorization': tokenHeader
                    }
                }).then((response) => {
                    setMessError(`L'utilisateur ${response.data.lastname} ${response.data.firstname} a bien été modifier. Attendez 5 secondes, vous allez être rediriger.`);
                    setTimeout(() => {
                        window.location = window.location.origin
                    }, 5000);
                }).catch((error) => {
                    if(error.response){
                        if(error.response.status === 401){
                            window.alert("Vous n'êtes pas autorisé à effectuer cette action.");
                            window.location = window.location.origin;
                        }else{
                            setMessError(error.response.data.message);
                        }
                    }
                });
            }
        }
    }

    const handleCheckBox = (e) => {
        const inputPassword = document.getElementById("password");
        const divVerifyPassword = document.getElementById("divVerifyPassword");
        if(e.target.checked){
            setPassword("");
            setChecked(true);
            inputPassword.disabled = false;
            divVerifyPassword.style.display = "flex";
        }else{
            setPassword("*******");
            setChecked(false);
            inputPassword.disabled = true;
            divVerifyPassword.style.display = "none";
        }
    }

    const handleBack = () => {
        var history = window.history;
        history.back();
    }

    return(
        <Container>
            <Button id="back" name="Retour" onClick={handleBack}/>
            <Form id="form" onSubmit={handleSubmit}>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                { messValid !== "" ? <Label name={ messValid }/> : <></> }

                { exist ?
                <>
                    <DivGroupInput>
                        <DivInput>
                            <Label name="Email :"/>
                            <Input value={email} id="email" type="email" disabled={true} name="email" onChange={ handleEmail } />
                        </DivInput>
                    </DivGroupInput>

                    <DivGroupInput>
                        <DivInput>
                            <Label name="Mot de passe :"/>
                            <Input value={password} id="password" type="password" disabled={true} name="password" onChange={ handlePassword }/>
                        </DivInput>
                    </DivGroupInput>

                    { !disable && type !== "admin" ?
                    <DivGroupInput>
                        <DivCheckBox>
                            <Input type="checkbox" id="checkboxRegie" name="checkboxRegie" onChange={ handleCheckBox } />
                            <Label name="Modiffier le mot de passe"/>
                        </DivCheckBox>
                    </DivGroupInput>
                    :
                    <></>
                    }

                    { !disable && type !== "admin" ?
                    <DivGroupInputHidden id="divVerifyPassword">
                        <DivInput>
                            <Label name="Saississer à nouveau le mot de passe :"/>
                            <Input value={verifyPassword} id="verifyPassword" type="text" name="verifyPassword" onChange={ handleVerifyPassword }/>
                        </DivInput>
                    </DivGroupInputHidden>
                    :
                    <></>
                    }

                    <DivGroupInput>
                        <DivInput>
                            <Label name="Nom :"/>
                            <Input value={lastname} id="lastname" type="text" disabled={disable} name="lastname" onChange={ handleLastname }/>
                        </DivInput>

                        <DivInput>
                            <Label name="Prénom :"/>
                            <Input value={firstname} id="firstname" type="text" disabled={disable} name="firstname" onChange={ handleFirstname }/>
                        </DivInput>
                    </DivGroupInput>

                    <DivGroupInput>
                        <DivInput>
                            <Label name="Type :"/>
                            <Select defaultValue={type} option={ selectType } disabled={disable} id="type" name="type" onChange={ handleType } />
                        </DivInput>
                    </DivGroupInput>

                    { type === "tech" ?
                    <DivGroupInput id="divEmailRegie">
                        <DivInput>
                            <Label name="Email de la régie gérant le technicien :"/>
                            <Select value={emailRegie} option={ optionRegie } disabled={disable} id="emailRegie" name="emailRegie" onChange={ handleEmailRegie }/>
                        </DivInput>
                    </DivGroupInput>
                    :
                    <></>
                    }

                    { typeUser === "admin" ?
                    <Submit>
                        <Input id="submit" type="submit" value="Modification" />
                    </Submit>
                    :
                    <></>
                    }
                </>
                :
                <></>
                }
                
            </Form>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 15px;

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 1150px){
        margin-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;

        #back{
            font-size: 15px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: start;

    label {
        font-size: 25px;
        text-align: justify;
        margin-bottom: 1%;
        font-weight: 700;
    }

    #messError{
        color: red;
    }
`;

const DivInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2%;
    width: 100%;

    input, select{
        font-size : 18px;
        border: solid 1px black;
        border-radius: 100px;
        padding: 1% 2%;
    }

    @media screen and (max-width: 750px){
        width: auto;

        input, select, label{
            font-size: 15px !important;
        }
    }
`;

const DivCheckBox = styled(DivInput)`
    flex-direction: row;
    align-items: center;

    label{
        text-align: justify;
        font-size: 18px;
    }
`;

const DivGroupInput = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 750px){
        flex-direction: column;
    }
`;

const DivGroupInputHidden = styled(DivGroupInput)`
    display: none;
`;

const Submit = styled.div`
    margin: 5% 0%;
    width: 100%;

    #submit{
        width: 50%;
        font-size: 18px;
        border-radius: 100px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 750px){
        #submit{
            font-size: 15px;
        }
    }
`;

export default InfoCompte;