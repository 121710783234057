import React from "react";
import styled from "styled-components";
import { FromConnex } from "../../molecules";

const HomeConnexOrga = ({ setTokenHeader }) => {
    return(
        <Container>
            <FromConnex setTokenHeader={ setTokenHeader } />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
    margin: auto;

    @media screen and (max-width: 900px){
        width: 50%;
    }

    @media screen and (max-width: 600px){
        width: 75%;
    }

    @media screen and (max-width: 300px){
        width: 85%;
    }
`;

export default HomeConnexOrga;