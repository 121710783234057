import React from "react";
import { HomeConnexTemplate } from '../../components/templates';

const HomeConnex = ({ setTokenHeader }) => {
    return (
        <>
            <HomeConnexTemplate setTokenHeader={ setTokenHeader } />
        </>
    );
}

export default HomeConnex;