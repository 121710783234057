import React from "react";
import styled from "styled-components";
import { FormNewProject } from "../../molecules";

const NewProjectOrga = ({ customerId, projectId }) => {

    return(
        <Container>
            <FormNewProject customerId={ customerId } projectId={ projectId }/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    height: inherit;
`;

export default NewProjectOrga;