import React from "react";

const Li = (props) => {
    let liId;
    if(props.id !== undefined){
        liId = "li" + props.id;
    }
    return(
        <>
            <li id={liId} style={props.style}>
                <a id={props.id} href={props.href} onClick={props.onClick}>{props.name}</a>
            </li>
        </>
    );
}

export default Li;