import React, { useEffect } from "react";
import { Button, Input, Label, Select} from "../../atoms";
import styled from "styled-components";
import axios from 'axios';

const FormStatusMPR = ({ projectId }) => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [statusMPR, setStatusMPR] = React.useState("");
    const [statusInstall, setStatusInstall] = React.useState("");
    const [optionStatusMPR, setOptionStatusMPR] = React.useState([]);
    const [optionStatusInstall, setOptionStatusInstall] = React.useState([]);
    const [messError, setMessError] = React.useState("");
    const [messValid, setMessValid] = React.useState("");

    useEffect(() => {
        const fetchCustomer = () => {
            setMessError("");
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                if(response.data.type !== "admin"){
                    window.alert("Vous n'êtes pas autorisé à effectuer cette action");
                    window.location = window.location.origin + "/404";
                }
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
            axios.get(process.env.REACT_APP_URL_API + 'project/' + projectId, {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                const project = response.data;
                setStatusMPR(project.statusMPR);
                const selectStatusMRP = document.getElementById("statusMPR");
                selectStatusMRP.value = project.statusMPR;
                setStatusInstall(project.statusInstallation);
                const selectStatusInstall = document.getElementById("statusInstall");
                selectStatusInstall.value = project.statusInstallation;
            }).catch((error) => {
                if(error.response){
                    setMessError(error.response.data.message);
                }
            });
            axios.get(process.env.REACT_APP_URL_API + 'project/statusMPR', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                let mpr = response.data.statusMPR;
                let inst = response.data.statusInstall;
                let listStatusMPR = [];
                let listStatusInstall = [];
                let id = 0;
                Object.keys(mpr).forEach(key => {
                    let tmp = {
                        id: id,
                        name: mpr[key],
                        value: mpr[key]
                    }
                    listStatusMPR.push(tmp);
                    id++;
                });
                id = 0;
                Object.keys(inst).forEach(key => {
                    let tmp = {
                        id: id,
                        name: inst[key],
                        value: inst[key]
                    }
                    listStatusInstall.push(tmp);
                    id++;
                });
                setOptionStatusMPR(listStatusMPR);
                setOptionStatusInstall(listStatusInstall);
            }).catch((error) => {
                if(error.response){
                    setMessError(error.response.data.message);
                }
            });
        };

        fetchCustomer();
    }, [tokenHeader, projectId]);
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setMessError("");
        setMessValid("");
        axios.patch(process.env.REACT_APP_URL_API + 'project/' + projectId, {
            statusMPR: statusMPR,
            statusInstallation: statusInstall
        }, {
            headers : {
                'Authorization': tokenHeader
            }
        }).then((response) => {
            let project = response.data;
            let status = "";
            let finish = false;
            switch(project.status){
                case "Nouveau": 
                    status = "nouveau";
                    break;
                case "En cours": 
                    status = "encours";
                    break;
                case "Finaliser": 
                    finish = true;
                    break;
                case "Supprimer": 
                    status = "supprime";
                    break;
                default: 
                    status = "annule";
                    break;
            }
            window.scrollTo(0,0);
            setMessValid("Le projet a bien été modifier.");
            if(finish){
                window.location = window.location.origin + "/projectsRedirect?id=" + project.tech.id;
            }else{
                window.location = window.location.origin + "/projectsRedirectStatus?status=" + status;
            }
        }).catch((error) => {
            if(error.response){
                window.scrollTo(0,0);
                setMessError(error.response.data.message);
            }
        });
    }

    const handleBack = () => {
        var history = window.history;
        history.back();
    }

    const handleStatusMPR = (event) => {
        setStatusMPR(event.target.value);
    }

    const handleStatusOption = (event) => {
        setStatusInstall(event.target.value);
    }

    return(
        <Container>
            <Button id="back" name="Retour" onClick={ handleBack }/>
            <Form onSubmit={handleSubmit}>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                { messValid !== "" ? <Label name={ messValid }/> : <></> }

                <DivGroupInput>
                    <DivInput>
                        <Label name="Status MPR :"/>
                        <Select defaultValue={ statusMPR } option={ optionStatusMPR } id="statusMPR" required={true} name="statusMPR" onChange={ handleStatusMPR } />
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Status Installation :"/>
                        <Select defaultValue={ statusInstall } option={ optionStatusInstall } id="statusInstall" required={true} name="statusInstall" onChange={ handleStatusOption } />
                    </DivInput>
                </DivGroupInput>

                <Submit>
                    <Input id="submit" type="submit" value="Modifier"/>
                </Submit>
                
            </Form>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 25px;

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 1350px){
        margin-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;

        #back{
            font-size: 15px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: start;

    label{
        text-align: justify;
        margin-bottom: 1%;
        margin-top: 1%;
        font-size: 22px;
        font-weight: 700;
    }

    #messError{
        color: red;
    }

    #divDevis{
        height: 500px;
    }
`;

const DivInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2%;
    width: 100%;

    @media screen and (max-width: 750px){
        width: auto;
        height: inherit;
    }

    select{
        font-size: 22px;
    }
`;

const DivGroupInput = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 750px){
        flex-direction: column;
    }
`;

const Submit = styled.div`
    margin: 5% 0%;
    width: 100%;

    #submit{
        width: 50%;
        font-size: 18px;
        border-radius: 100px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 750px){
        #submit{
            font-size: 15px;
        }
    }
`;

export default FormStatusMPR;