import React from "react";
import { Redirect } from "react-router";
import { ProjectTemplate } from "../../components/templates";

const Project = props => {
    let projectId = "0";
    if(props.location){
        projectId = props.location.state.projectId;
    }
    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404" />
    }else{
        return (
            <>
                <ProjectTemplate projectId={ projectId }/>
            </>
        )
    }
}

export default Project;