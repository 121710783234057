import React from "react";
import styled from "styled-components";
import { InfoCompte } from "../../molecules";

const CompteOrga = ({ compteId }) => {

    return(
        <Container>
            <InfoCompte compteId={ compteId }/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    height: inherit;
`;

export default CompteOrga;