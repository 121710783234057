import React from "react";
import { Redirect } from "react-router";

const NewFactureRedirect = () => {

    const pId = window.location.search.split("=")[1];
    return <Redirect to={{
        pathname: "/newFacture",
        state: {
            projectId: pId
        }
    }}/>
}

export default NewFactureRedirect;