import React from "react";
import styled from "styled-components";
import { BtnProject } from "../../molecules";

const ProjectOrga = ({ projectId }) => {

    return(
        <Container>
            <BtnProject projectId={ projectId }/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
    height: inherit;
`;

export default ProjectOrga;