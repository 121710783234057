import axios from "axios";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Ahref, Button, Label, Li } from "../../atoms";

const ListComptes = ({ status }) => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [comptes, setComptes] = React.useState([]);
    const [typeUser, setTypeUser] = React.useState("");
    const [messError, setMessError] = React.useState("");

    useEffect(() => {
        const fetchCustomer = () => {
            setMessError("");
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                setTypeUser(response.data.type);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
            axios.get(process.env.REACT_APP_URL_API + 'user',{
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                let res = response.data;
                let tmp = [];
                if(status === "finish"){
                    for(let r of res){
                        if(r.type !== "admin"){
                            tmp.push(r);
                        }
                    }
                    res = tmp;
                }
                setComptes(res);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status){
                        setMessError(error.response.data.message);
                    }else{
                        window.alert("Une erreur est survenue.");
                        window.location = window.location.origin + "/404";
                    }
                }
            })
        };

        fetchCustomer();
    }, [tokenHeader, status]);

    const handleBack = () => {
        var history = window.history;
        history.back(); 
    }

    const handleCompte = (e) => {
        e.preventDefault();
        setMessError("");
        if(typeUser === "admin"){
            if(status !== "finish"){
                window.location = window.location.origin + "/compteRedirect?id=" + e.target.id;
            }else{
                window.location = window.location.origin + "/projectsRedirect?id=" + e.target.id;
            }
        }else if(typeUser === "regie"){
            window.location = window.location.origin + "/projectsRedirect?id=" + e.target.id;
        }else{
            setMessError("Vous n'êtes pas authorizer à effectuer cette action");
        }
    }

    const handleRegieTech = (e) => {
        e.preventDefault();
        const aRegie = e.target;
        if(aRegie.id === "plus"){
            aRegie.style = "width: 50%";
        }else{
            const nestElement = aRegie.nextElementSibling;
            nestElement.style = "width: 50%";
        }
        const liRegie = e.target.parentElement;
        let tmp  = liRegie.id.split('li');
        const idLi = parseInt(tmp[1]);
        if(e.target.parentElement.className === "handleRegieTech"){
            const lastChild = liRegie.lastChild;
            liRegie.removeChild(lastChild);
            liRegie.className = "";
        }else{
            let techId = [];
            let liInnerHTML = ``;
            let liArray;
            comptes.map((c) => {
                if(idLi === c.id){
                    liArray = c.tech.map(t => {
                        techId.push(t.id);
                        return(
                            `<li id="li${t.id}">
                                <a href="#" id="${t.id}">${t.lastname + " " + t.firstname}</a>
                            </li>`
                        )
                    })
                }
                return true;
            });
            liInnerHTML = 
            `<ul>
                ${ liArray.join('')}
            </ul>`;
            liRegie.insertAdjacentHTML('beforeend', liInnerHTML);
            techId.map(e => {
                const apro = document.getElementById(e);
                apro.addEventListener("click", handleCompte);
                return true;
            });
            liRegie.className = "handleRegieTech";
        }
    }

    return(
        <Container>
            <Button id="back" name="Retour" onClick={ handleBack }/>
            { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
            <Ul>
                { typeUser === "admin" ?
                <>
                    { status !=="finish" ?
                        <Li id="new" name="Nouveau utilisateur" href="/newCompte"/>
                        :
                        <></>
                    }
                </>
                :
                <></>
                }
                { comptes.map(e => {
                    if(e.tech.length === 0){
                        return (
                            <Li href="#" key={e.id} id={e.id} name={e.lastname + " " + e.firstname} onClick={ handleCompte }/>
                        )
                    }else{
                        return (
                            <li key={e.id} id={"li" + e.id}>
                                <Ahref id={e.id} href="#" onClick={ status !== "finish" ? handleCompte : handleRegieTech } name={e.lastname + " " + e.firstname}/>
                                <Ahref id="plus" href="#" name="+" onClick={ handleRegieTech }/>
                            </li>
                        )
                    }
                }) }
            </Ul>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 15px;
    font-size: 25px;

    label{
        text-align: justify;
        margin-bottom: 2%;
        color: red;
    }

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 25px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }

    @media screen and (max-width: 1350px){
        margin-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;
        font-size: 15px;

        #back{
            font-size: 15px;
        }
    }
`;

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    text-align: justify;
    width: 100%;

    .handleRegieTech{
        border-radius: 15px !important;
    }

    li{
        margin-bottom: 1em;
        display: flex;
        flex-wrap: wrap;
        border: solid 1px black;
        border-radius: 100px;
        background: white;

        ul{
            margin: 1em;
            text-align: justify;
            list-style: none;
            font-size: 20px;
            flex-grow: 1;
            width: 100%;

            li{
                margin-bottom : 1em;
            }
        }
    }

    li:before{
        content: "◆";
        margin-right: 7px;
        margin-left: 10px;
        align-self: center;
    }

    #linew:before{
        content: "+";
        margin-right: 7px;
        margin-left: 10px;
    }

    a{
        flex-grow: 10;
        padding: 1%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 25%;
        align-self: center;
    }

    #plus{
        width: auto;
        overflow: inherit;
        flex-grow: 1;
        font-size: 25px;
        text-align: end;
    }
`;

export default ListComptes;