import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Ahref, Button, Image, Input, Label } from "../../atoms";
import IconCrayon from "../../../image/icon_crayon.png";

const ListCustomer = () => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [customers, setCustomers] = useState([]);
    const [listCustomer, setListCustomer] = useState([]);
    const [typeUser, setTypeUser] = React.useState("");
    const [messError, setMessError] = React.useState("");

    useEffect(() => {
        const fetchCustomer = () => {
            setMessError("");
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                setTypeUser(response.data.type);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
            axios.get(process.env.REACT_APP_URL_API + 'customer',{
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                setListCustomer(response.data);
                setCustomers(response.data);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status){
                        setMessError(error.response.data.message);
                    }else{
                        window.alert("Une erreur est survenue.");
                        window.location = window.location.origin + "/404";
                    }
                }
            })
        };

        fetchCustomer();
    }, [tokenHeader]);

    const handleCustomer = (e) => {
        e.preventDefault();
        const customerId = e.target.id;
        window.location = window.location.origin + "/newProjectRedirect?cid=" + customerId;
    }

    const handleInfoCustomer = (e) => {
        e.preventDefault();
        let customerId;
        if(e.target.id === "modif") {
            customerId = e.target.parentNode.id;
        }else{
            customerId = e.target.parentNode.parentNode.id;
        }
        window.location = window.location.origin + "/customerRedirect?cid=" + customerId;
    }

    const handleBack = () => {
        var history = window.history;
        history.back();
    }

    const handleSearch = (e) => {
        const value = e.target.value;
        let result = customers.filter(function(c){
            let exist = false;
            let lastname = c.lastname.toLowerCase();
            let firstname = c.firstname.toLowerCase();
            if(lastname.indexOf(value.toLowerCase()) !== -1){
                exist = true;
            }
            if(firstname.indexOf(value.toLowerCase()) !== -1){
                exist = true;
            }
            return exist;
        })
        setListCustomer(result);
    }

    const style = {
        "width": "25px"
    }

    return(
        <Container>
            { typeUser === "tech" ?
            <>
                <Button id="back" name="Retour" onClick={handleBack}/>
                <DivSearch>
                    <Label id="search" name="Rechercher : "/>
                    <Input id="search" type="text" name="search" onChange={ handleSearch }/>
                </DivSearch>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                { listCustomer.length === 0 ?
                    <Label id="mess" name="Aucun client correspond à la recherche."/>
                    :
                    <></>
                }
                <Ul>
                    { listCustomer.map(e => {
                        return (
                            <li key={e.id} id={e.id}>
                                <Ahref id={e.id} href="#" onClick={ handleCustomer } name={e.genre + " " + e.lastname + " " + e.firstname}/>
                                <Ahref id="modif" href="#" name={<Image src={ IconCrayon } alt="Modifier" style={style}/>} onClick={ handleInfoCustomer }/>
                            </li>
                        )
                    }) }
                </Ul>
            </>
            :
            <></>
            }
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 15px;
    font-size: 25px;

    label{
        text-align: justify;
        margin-bottom: 2%;
        color: red;
    }

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 20px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 1350px){
        margi-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;

        #back{
            font-size: 15px;
        }
    }
`;

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    text-align: justify;
    width: 100%;

    li{
        margin-bottom: 1em;
        display: flex;
        flex-wrap: wrap;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }

    a{
        flex: auto;
        padding: 1%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        align-self: center;
    }

    #modif{
        flex: none;
    }
`;

const DivSearch = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 35px;

    label{
        color: black;
        margin: 0%;
        font-size: 20px;
    }

    input{
        margin-left: 10px;
    }
`;

export default ListCustomer;