import React from "react";
import { Redirect } from "react-router";
import { NewProjectTemplate } from "../../components/templates";

const NewProject = props => {
    const { customerId, projectId } = props.location.state;
    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404" />
    }else{
        return (
            <>
                <NewProjectTemplate customerId={ customerId } projectId={ projectId }/>
            </>
        )
    }
}

export default NewProject;