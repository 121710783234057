import React from "react";
import styled from "styled-components";
import { Button } from "../../atoms";
import { Logo, Error } from "../../molecules";

const ErrorOrga = props => {
    const styleDiv = {
        width: "35%"
    }

    const handleBack = () => {
        window.location = window.location.origin;
    }

    return(
        <Container>
            <Logo widthLogo="100%" styleDiv={styleDiv}/>
            <Error name={props.name}/>
            <Button id="back" name="Accueil" onClick={ handleBack }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    background-color: white;
    border-radius: 25px;
    flex-direction: column;
    margin: auto;
    justify-content: space-evenly;
    width: 55%;
    align-items: center;
    padding: 2%;
    
    #back{
        width: 25%;
        align-self: center;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
        box-shadow: 10px 4px 8px black;
        margin-top: 5%;
    }
`;

export default ErrorOrga;