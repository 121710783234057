import React from "react";
import styled from "styled-components";
import { ProjectOrga } from "../../organisms";

const ProjectTemplate = ({ projectId }) => {
    return (
        <Container>
            <ProjectOrga projectId={ projectId }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default ProjectTemplate;