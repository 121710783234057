import axios from "axios";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Label, Li } from "../../atoms";

const BtnProject = ({ projectId }) => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [typeUser, setTypeUser] = React.useState("");
    const [project, setProject] = React.useState({});

    useEffect(() => {
        const fetchCustomer = async () => {
            let userType = "";
            let status = "";
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                userType = response.data.type;
                setTypeUser(response.data.type);
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        window.alert("Une erreur est survenue.");
                        window.location = window.location.origin + "/404";
                    }
                }
            });
            await axios.get(process.env.REACT_APP_URL_API + 'project/' + projectId, {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                status = response.data.status;
                setProject(response.data);
            }).catch((error) => {
                if(error.response){
                    window.alert("Une erreur est survenue.");
                    window.location = window.location.origin;
                }
            });
            if(userType === "admin" && status === "Nouveau"){
                axios.patch(process.env.REACT_APP_URL_API + 'project/' + projectId, {
                    status: "En cours"
                }, {
                    headers : {
                        'Authorization': tokenHeader
                    } 
                }).then((response) => {
                    setProject(response.data);
                }).catch((error) => {
                    if(error.response){
                        window.alert("Une erreur est survenue.");
                        window.location = window.location.origin;
                    }
                });
            }
        };

        fetchCustomer();
    }, [tokenHeader, projectId]);

    const handleBack = (e) => {
        e.preventDefault();
        var history = window.history;
        history.back();
    }

    const handleDowload = (e) => {
        e.preventDefault();
        axios.get(process.env.REACT_APP_URL_API + "project/file/" + project.customer.id + "/" + project.id, {
            headers : {
                'Authorization': tokenHeader
            }
        }).then((response) => {
            const zipFolder = response.data;
            let bufferFile = zipFolder.base64;
            var contentType = 'application/zip';
            var sliceSize = 1024;
            const bufferBase64 = Buffer.from(bufferFile, "base64");
            const byteCharacters = bufferBase64.toString("latin1");
            var bytesLength = byteCharacters.length;
            var slicesCount = Math.ceil(bytesLength / sliceSize);
            var byteArrays = new Array(slicesCount);

            for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                var begin = sliceIndex * sliceSize;
                var end = Math.min(begin + sliceSize, bytesLength);

                var bytes = new Array(end - begin);
                for (var offset = begin, i = 0 ; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            var blob = new Blob(byteArrays, { type: contentType });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob, { type: "application/zip" });
            link.download = zipFolder.folderName + ".zip";
            link.click();
        }).catch((error) => {
            window.alert("Le dossier n'a pas pu être télécharger, veuillez réessaye ultérieurement.");
        });
    }

    const handleValid = async (e) => {
        e.preventDefault();
        const text = "Êtes vous sûr de vouloir valider ce projet ?";
        const confirm = window.confirm(text);
        if(confirm === true){
            await axios.patch(process.env.REACT_APP_URL_API + 'project/' + projectId, {
                status: "Finalise"
            }, {
                headers : {
                    'Authorization': tokenHeader
                } 
            }).then((response) => {
                setProject(response.data);
            }).catch((error) => {
                window.alert("Une erreur est survenue, veuillez réessaye ultérieurement.");
            });
        }
    }

    const handleRefu = async (e) => {
        e.preventDefault();
        const text = "Saississez en détail les éléments manquant.";
        const prompt = window.prompt(text);
        if(prompt !== null){
            if(prompt !== ""){
                await axios.patch(process.env.REACT_APP_URL_API + 'project/' + projectId, {
                    status: "A completer",
                    commentary: prompt
                }, {
                    headers : {
                        'Authorization': tokenHeader
                    } 
                }).then((response) => {
                    setProject(response.data);
                }).catch((error) => {
                    window.alert("Une erreur est survenue.");
                });
            }else{
                window.alert("Vous n'avez pas indiquez les éléments manquant.");
            }
        }
    }

    const handleAjout = (e) => {
        e.preventDefault();
        window.location = window.location.origin + "/addFileAfterRedirect?id=" + projectId;
    }

    const handleTech = (e) => {
        e.preventDefault();
        window.location = window.location.origin + "/compteRedirect?id=" + project.tech.id;
    }

    const handleStatusMPR = (e) => {
        e.preventDefault();
        window.location = window.location.origin + "/statusMPRRedirect?id=" + project.id;
    }

    const handleContinu = (e) => {
        e.preventDefault();
        window.location = window.location.origin + "/newProjectRedirect?cid=" + project.customer.id + "&pId=" + project.id;
    }

    return(
        <Container>
            { project.status === "A completer" && typeUser === "tech" ?
                <>
                    <Label name="Commentaire : "/>
                    <Label id="ToComplete" name={ project.commentary }/>
                </>
            :
                <></>    
            }
            <Ul>
            { project.status === "Creation" ?
                <Li name="Continuer le projet" href="#" onClick={ handleContinu }/>
            :
                <>
                    <Li name="Télécharger le dossier" href="#" onClick={ handleDowload }/>
                    { project.status === "A completer" && typeUser === "tech" ?
                        <>
                            <Li name="Ajouter" href="#" onClick={ handleAjout }/>
                        </>
                    :
                        <></>
                    }
                    { typeUser !== "tech" ?
                        <Li name="Technicien" href="#" onClick={ handleTech } />
                        :
                        <></>
                    }
                </>
            }
                <Li name="Retour" href="#" onClick={ handleBack } />
            </Ul>
            <Ul>
            { project.status === "En cours" && typeUser === "admin" ?
                <>
                    <Li name="Valider" href="#" onClick={ handleValid }/>
                    <Li name="Refuser" href="#" onClick={ handleRefu }/>
                </>
            :
                <></>
            }
            { typeUser === "admin" ?
                <Li name="Changer status MPR" href="#" onClick={ handleStatusMPR } />
                :
                <></>
            }
            </Ul>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    font-size: 25px;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 75px;

    @media screen and (max-width: 750px){
        font-size: 15px;
    }

    #ToComplete{
        color: red;
        font-weight: 700;
        text-align: justify;
        height: 25%;
        overflow: auto;
        padding: 1%;
    }
`;

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 5%;

    li{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 5% 1%;
    }

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5%;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
`;

export default BtnProject;