import React from "react";
import { Image } from "../../atoms";
import LogoSoelys from "../../../image/soelys_logo.png";
import styled from "styled-components";

const Logo = (props) => {
    const style = {
        "width": props.widthLogo
    }

    return(
        <Container style={props.styleDiv}>
            <Image src={ LogoSoelys } alt="Logo soelys" style={style}/>
        </Container>
    );
}

const Container = styled.div``;

export default Logo;