import React from "react";
import styled from "styled-components";
import { NewFactureOrga } from "../../organisms";

const NewFactureTemplate = ({ projectId }) => {

    return (
        <Container>
            <NewFactureOrga projectId={ projectId }/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

export default NewFactureTemplate;