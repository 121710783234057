import React, { useEffect } from "react";
import { Button, Input, Label, Select } from "../../atoms";
import styled from "styled-components";
import ImgVol from "../../../image/fichier_volumineux.png";
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';

const FormNewCustomer = () => {

    const tokenHeader = localStorage.getItem("tokenHeader");
    const [checked, setChecked] = React.useState(false);
    const [messError, setMessError] = React.useState("");
    const [messValid, setMessValid] = React.useState("");

    useEffect(() => {
        const fetchCustomer = () => {
            setMessError("");
            axios.get(process.env.REACT_APP_URL_API + 'user/auth', {
                headers : {
                    'Authorization': tokenHeader
                }
            }).then((response) => {
                if(response.data.type !== "tech"){
                    window.alert("Vous n'êtes pas authorisé à effectuer cette action.");
                    window.location = window.location.origin;
                }
            }).catch((error) => {
                if(error.response){
                    if(error.response.status === 401){
                        var storage = window.localStorage;
                        storage.clear();
                        window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                        window.location = window.location.origin;
                    }else{
                        setMessError(error.response.data.message);
                    }
                }
            });
        };

        fetchCustomer();
    }, [tokenHeader]);

    const [email, setEmail] = React.useState("");
    const [telPort, setTelPort] = React.useState("");
    const [genre, setGenre] = React.useState("");
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [city, setCity] = React.useState("");
    const [pc, setPc] = React.useState("");
    const [surface, setSurface] = React.useState("");
    const [nbPiece, setNbPiece] = React.useState("");
    const [nbPersoCharge, setNbPersoCharge] = React.useState("");
    const [energieActu, setEnergieActu] = React.useState("");
    const [energieFuture, setEnergieFuture] = React.useState("");
    const [lastnameLoca, setLastnameLoca] = React.useState("");
    const [addressLoca, setAddressLoca] = React.useState("");
    const [cityLoca, setCityLoca] = React.useState("");
    const [pcLoca, setPcLoca] = React.useState("");
    const [files, setFiles] = React.useState([]);
    const [codePrime, setCodePrime] = React.useState("");

    const strUcFirst = (string) => {
        const str = string.toLowerCase();
        return (str+'').charAt(0).toUpperCase()+str.substr(1);
    }

    const strLc = (string) => {
        return string.toLowerCase()
    }

    const handleEmail = (event) => {
        const emailTarget = strLc(event.target.value);
        setEmail(emailTarget);
    }
    
    const handleTelPort = (event) => { 
        setTelPort(event.target.value);
    }

    const handleGenre = (event) => {
        var checkboxes = document.getElementsByName("genre");
        checkboxes.forEach((element) => {
            if(element !== event.target) element.checked = false;
        });
        setGenre(event.target.id);
    }

    const handleFirstname = (event) => { 
        const firstnameTarget = strUcFirst(event.target.value);
        setFirstname(firstnameTarget);
    }

    const handleLastname = (event) => {
        const lastnameTarget = strUcFirst(event.target.value);
        setLastname(lastnameTarget);
    }

    const handleAddress = (event) => {
        setAddress(event.target.value);
    }

    const handleCity = (event) => {
        const cityTarget = strUcFirst(event.target.value);
        setCity(cityTarget);
    }

    const handlePc = (event) => { 
        setPc(event.target.value);
    }

    const handleSurface = (event) => {
        setSurface(event.target.value);
    }
    
    const handleNbPiece = (event) => {
        setNbPiece(event.target.value);
    }

    const handleNbPersoCharge = (event) => {
        setNbPersoCharge(event.target.value);
    }

    const handleEnergieActu = (event) => {
        setEnergieActu(event.target.value);
    }

    const handleEnergieFuture = (event) => {
        setEnergieFuture(event.target.value);
    }

    const handleLastnameLoca = (event) => {
        const lastnameLocaTarget = strUcFirst(event.target.value);
        setLastnameLoca(lastnameLocaTarget);
    }

    const handleAddressLoca = (event) => {
        setAddressLoca(event.target.value);
    }

    const handleCityLoca = (event) => {
        const cityLocaTarget = strUcFirst(event.target.value);
        setCityLoca(cityLocaTarget);
    }

    const handlePcLoca = (event) => { 
        setPcLoca(event.target.value);
    }

    const handleFiles = (event) => {
        const userFiles = event.target.files;
        let divFiles = event.target.nextElementSibling;
        let fileByteArray = files;
        setMessError("");
        if(userFiles){
            for(const file of userFiles){
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async () => {
                    let fileNotAccept = false;
                    let exist = event.target.attributes["nb"].value;
                    let filename = file.name.split(".");
                    let type = filename[filename.length - 1];
                    filename = event.target.id;
                    if(exist !== 0){
                        filename = filename + "_" + exist;
                    }
                    exist++;
                    event.target.attributes["nb"].value = exist;
                    let fil = {};
                    let tmp = reader.result.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                    let typeTmp = tmp.split("/")[1];
                    let data = tmp.split("/")[0];
                    if(data === "image"){
                        const divFile = document.createElement("div");
                        divFile.id = "divFile";
                        const img = document.createElement('img');
                        img.setAttribute("src", reader.result);
                        img.setAttribute("style", "width: 100%");
                        const imgTitle = document.createElement("label");
                        imgTitle.innerText = filename;
                        const deleteImg = document.createElement("a");
                        deleteImg.href = "#";
                        deleteImg.innerText = "X";
                        deleteImg.id = filename;
                        deleteImg.addEventListener("click", handleDeleteFile);
                        divFile.appendChild(img);
                        divFile.appendChild(imgTitle);
                        divFile.appendChild(deleteImg);
                        divFiles.appendChild(divFile);
                    }else if(data === "application"){
                        if(typeTmp === "pdf"){
                            const divFile = document.createElement("div");
                            divFile.id = "divFile";
                            const iframe = document.createElement('iframe');
                            let result = reader.result;
                            if(reader.result.length > 500000){
                                result = ImgVol;
                            }
                            iframe.setAttribute("src", result);
                            iframe.setAttribute("width", "200");
                            iframe.setAttribute("title", filename);
                            const imgTitle = document.createElement("label");
                            imgTitle.innerText = filename;
                            const deleteImg = document.createElement("a");
                            deleteImg.href = "#";
                            deleteImg.innerText = "X";
                            deleteImg.id = filename;
                            deleteImg.addEventListener("click", handleDeleteFile);
                            divFile.appendChild(iframe);
                            divFile.appendChild(imgTitle);
                            divFile.appendChild(deleteImg);
                            divFiles.appendChild(divFile);
                        }else{
                            fileNotAccept = true;
                        }
                    }else{
                        fileNotAccept = true;
                    }
                    if(!fileNotAccept){
                        fil.title = filename;
                        if(data === "image"){
                            const pdf = await PDFDocument.create();
                            let image;
                            if(typeTmp === "png"){
                                image = await pdf.embedPng(reader.result);
                            }else if(typeTmp === "jpeg" || typeTmp === "jpg"){
                                image = await pdf.embedJpg(reader.result);
                            }else{
                                image = undefined;
                            }
                            if(image){
                                const pageImg = pdf.addPage();
                                const ScaleImg = image.scale(1);
                                let width = ScaleImg.width > pageImg.getWidth() ? ScaleImg.width / 2 : ScaleImg.width;
                                let height = ScaleImg.height > pageImg.getHeight() ? ScaleImg.height / 2 : ScaleImg.height;
                                while(width > pageImg.getWidth()){
                                    width = width / 1.5;
                                    height = height / 1.5;
                                }
                                while(height > pageImg.getHeight()){
                                    width = width / 1.5;
                                    height = height / 1.5;
                                }
                                pageImg.drawImage(image, {
                                    x: 10,
                                    y: pageImg.getHeight() - height - 10,
                                    width: width,
                                    height: height,
                                });
                                const U8A = await pdf.saveAsBase64();
                                fil.result = U8A;
                                fil.type = "pdf";
                                fileByteArray.push(fil);
                            }else{
                                window.scrollTo(0,0);
                                setMessError("Le fichier que vous essayez d'ajouter n'est pas accepté.");
                            }
                        }else{
                            fil.result = reader.result;
                            fil.type = type;
                            fileByteArray.push(fil);
                        }
                    }else{
                        setMessError("Le fichier que vous essayez d'ajouter n'est pas accepté.");
                    }
                }
                reader.onerror = (error) => {
                    setMessError(error);
                };                
            }
        }
        setFiles(fileByteArray);
    }

    const handleDeleteFile = (e) => {
        e.preventDefault();
        const name = e.target.id;
        const divImg = e.target.parentNode;
        const parentNode = divImg.parentNode;
        let fileByteArray = files;
        for(let i = 0; i < fileByteArray.length; i++){
            if(fileByteArray[i].title === name){
                fileByteArray.splice(i, 1);
            }
        }
        parentNode.removeChild(divImg);
        setFiles(fileByteArray);
    }

    const handleCodePrime = (e) => {
        setCodePrime(e.target.value);
    }

    const optionEnergieActu = [
        {
            id: 1,
            value: "",
            name: "Choisissez l'énergie actuelle de la maison.",
            disabled: true,
            hidden: true
        },
        {
            id: 2,
            value: "GAZ",
            name: "GAZ"
        },
        {
            id: 3,
            value: "FIOUL",
            name: "FIOUL"
        },
        {
            id: 4,
            value: "BOIS",
            name: "BOIS"
        },
        {
            id: 5,
            value: "ELECTRIQUE",
            name: "ELECTRIQUE"
        },
    ]

    const optionEnergieFutur = [
        {
            id: 1,
            value: "",
            name: "Choisissez l'énergie futur de la maison.",
            disabled: true,
            hidden: true
        },
        {
            id: 2,
            value: "GAZ",
            name: "GAZ"
        },
        {
            id: 3,
            value: "FIOUL",
            name: "FIOUL"
        },
        {
            id: 4,
            value: "BOIS",
            name: "BOIS"
        },
        {
            id: 5,
            value: "ELECTRIQUE",
            name: "ELECTRIQUE"
        },
    ]

    const handleSubmit = (event) => {
        event.preventDefault();
        setMessError("");
        setMessValid("");
        var btnCreation = document.getElementById("submit");
        btnCreation.disabled = true;
        let locataire = {
            lastname: "",
            address: "",
            pc: "",
            city: ""
        };
        if(checked){
            locataire = {
                lastname: lastnameLoca,
                address: addressLoca,
                pc: pcLoca,
                city: cityLoca
            }
        }
        locataire = JSON.stringify(locataire);
        axios.post(process.env.REACT_APP_URL_API + 'customer/add', {
            email,
            telPortable: telPort,
            genre,
            firstname,
            lastname,
            address,
            city,
            pc,
            surface,
            nbPiece,
            nbPersoCharge,
            energieActu,
            energieFuture,
            locataire,
            files,
            codePrime
        }, {
            headers: { 
                'Authorization': tokenHeader
            }
        }).then((response) => {
            window.scrollTo(0,0);
            setMessValid(`Le client ${response.data.lastname} ${response.data.firstname} a bien été créer.`);
            setTimeout(() => {
                window.location = window.location.origin + "/newProjectRedirect?cid=" + response.data.id;
            }, 1000);
            btnCreation.disabled = false;
        }).catch((error) => {
            if(error.response){
                if(error.response.status === 401){
                    var storage = window.localStorage;
                    storage.clear();
                    window.alert("Votre session a expiré. Veuillez vous reconnecter.");
                    window.location = window.location.origin;
                }else{
                    window.scrollTo(0,0);
                    if(!Array.isArray(error.response.data.message)){
                        setMessError(error.response.data.message);
                    }else{
                        let merr = "";
                        for(var mess of error.response.data.message){
                            merr += `${merr} \n ${mess}`;
                        }
                        setMessError(merr);
                    }
                }
            }
            btnCreation.disabled = false;
        });
    }

    const handleBack = () => {
        var history = window.history;
        history.back(); 
    }

    const handleCheckBox = (e) => {
        if(e.target.checked){
            setChecked(true);
        }else{
            setChecked(false);
        }
    }

    return(
        <Container>
            <Button id="back" name="Retour" onClick={ handleBack }/>
            <Form onSubmit={handleSubmit}>
                { messError !== "" ? <Label id="messError" name={ messError }/> : <></> }
                { messValid !== "" ? <Label name={ messValid }/> : <></> }

                <Label name="Information du client :"/>

                <DivGroupInput id="DivGenre">
                    <DivCheckBox>
                        <Label name="M. "/>
                        <Input type="checkbox" id="Monsieur" name="genre" onChange={ handleGenre }/>
                        <Label name="ou Mme "/>
                        <Input type="checkbox" id="Madame" name="genre" onChange={ handleGenre }/>
                    </DivCheckBox>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Nom :"/>
                        <Input value={lastname} id="lastname" type="text" required={true} name="lastname" onChange={ handleLastname }/>
                    </DivInput>

                    <DivInput>
                        <Label name="Prénom :"/>
                        <Input value={firstname} id="firstname" type="text" required={true} name="firstname" onChange={ handleFirstname }/>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Email :"/>
                        <Input value={email} id="email" type="email" required={true} name="email" onChange={ handleEmail } />
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Numéro de téléphone portable :"/>
                        <Input value={telPort} id="telPort" type="text" required={true} name="telPort" onChange={ handleTelPort }/>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Adresse :"/>
                        <Input value={address} id="address" type="text" required={true} name="address" onChange={ handleAddress }/>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Ville :"/>
                        <Input value={city} id="city" type="text" required={true} name="city" onChange={ handleCity }/>
                    </DivInput>

                    <DivInput>
                        <Label name="Code postale :"/>
                        <Input value={pc} id="pc" type="string" required={true} name="pc" onChange={ handlePc }/>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Avis d'impôt :"/>
                        <input nb="0" className="files" id="avis_impot" type="file" required={true} name="files" accept="image/*,.pdf" onChange={ handleFiles }/>
                        <div id="divFiles"></div>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Code MaPrimeRénov' :"/>
                        <Input value={codePrime} id="codePrime" type="text" required={true} name="codePrime" onChange={ handleCodePrime }/>
                    </DivInput>
                </DivGroupInput>

                <Label name="Information de la maison d'installation :"/>

                <DivGroupInput id="input">
                    <DivInput>
                        <Label name="Surface en m2 :"/>
                        <Input value={surface} id="surface" type="number" required={true} name="surface" min={1} onChange={ handleSurface }/>
                    </DivInput>

                    <DivInput>
                        <Label name="Nombre de pièce :"/>
                        <Input value={nbPiece} id="nbPiece" type="number" required={true} name="nbPiece" min={1} onChange={ handleNbPiece }/>
                    </DivInput>

                    <DivInput id="nbPerChar">
                        <Label name="Nombre de personne à charge :"/>
                        <Input value={nbPersoCharge} id="nbPersoCharge" type="number" required={true} name="nbPersoCharge" min={0} onChange={ handleNbPersoCharge }/>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivInput>
                        <Label name="Énergie actuelle :"/>
                        <Select defaultValue={''} option={ optionEnergieActu } id="energieActu" required={true} name="energieActu" onChange={ handleEnergieActu }/>
                    </DivInput>

                    <DivInput>
                        <Label name="Énergie future :"/>
                        <Select defaultValue={''} option={ optionEnergieFutur } id="energieFuture" required={true} name="energieFuture" onChange={ handleEnergieFuture }/>
                    </DivInput>
                </DivGroupInput>

                <DivGroupInput>
                    <DivCheckBox>
                        <Input type="checkbox" id="checkboxRegie" name="checkboxRegie" onChange={ handleCheckBox } />
                        <Label name="Le client est propriétaire bailleur"/>
                    </DivCheckBox>
                </DivGroupInput>

                { checked ?
                <>
                    <Label name="Information du locataire :"/>

                    <DivGroupInput>
                        <DivInput>
                            <Label name="Nom du locataire :"/>
                            <Input value={lastnameLoca} id="lastnameLoca" type="text" required={true} name="lastnameLoca" onChange={ handleLastnameLoca }/>
                        </DivInput>
                    </DivGroupInput>

                    <DivGroupInput>
                        <DivInput>
                            <Label name="Adresse du locataire :"/>
                            <Input value={addressLoca} id="addressLoca" type="text" required={true} name="addressLoca" onChange={ handleAddressLoca }/>
                        </DivInput>
                    </DivGroupInput>

                    <DivGroupInput>
                        <DivInput>
                            <Label name="Ville du locataire :"/>
                            <Input value={cityLoca} id="cityLoca" type="text" required={true} name="cityLoca" onChange={ handleCityLoca }/>
                        </DivInput>

                        <DivInput>
                            <Label name="Code postale du locataire :"/>
                            <Input value={pcLoca} id="pcLoca" type="text" required={true} name="pcLoca" onChange={ handlePcLoca }/>
                        </DivInput>
                    </DivGroupInput>

                    <DivGroupInput>
                        <DivInput>
                            <Label name="Taxe foncière ou Acte notarié :"/>
                            <input nb="0" className="files" id="taxe_fonciere_acte_notarie" type="file" required={true} name="files" accept="image/*,.pdf" onChange={ handleFiles }/>
                            <div id="divFiles"></div>
                        </DivInput>
                    </DivGroupInput>
                </>
                :
                <></>
                }

                <Submit>
                    <Input id="submit" type="submit" value="Création" />
                </Submit>
                
            </Form>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1% 2% 0% 2%;
    margin-top: 2%;
    background-image: linear-gradient(to bottom right, #FBFE00, #0133FE);
    border-radius: 25px;

    #back{
        width: 25%;
        margin: 0px 10px 0px auto;
        font-size: 18px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
    }
    
    @media screen and (max-width: 1350px){
        margin-top: 12%;
    }

    @media screen and (max-width: 750px){
        margin-top: 30%;

        #back{
            font-size: 15px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: start;

    label{
        text-align: justify;
        margin-bottom: 1%;
        margin-top: 1%;
        font-size: 22px;
        font-weight: 700;
    }

    
    @media screen and (max-width: 1350px){
        #input{
            flex-wrap: wrap;

            div{
                width: 46%;
            }

            #nbPerChar{
                width: 96%
            }
        }
    }

    @media screen and (max-width: 750px){
        label{
            font-size: 17px
        }

        #input{
            div{
                width: 96%;
            }
        }
    }

    #messError{
        color: red;
    }

    .files{
        border: 0px solid;
    }
`;

const DivInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2%;
    width: 100%;

    input, select{
        font-size : 15px;
        border: solid 1px black;
        border-radius: 100px;
        padding: 1% 2%;
    }

    label {
        font-size: 20px;
        text-align: justify;
        margin-bottom: 1%;
        font-weight: 700;
    }

    @media screen and (max-width: 750px){
        width: auto;

        input, select, label{
            font-size: 15px !important;
        }
    }

    #divFiles{
        display: flex;
        margin-top: 2%;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    #divFile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 29%;
        padding: 1%;
        margin: 1%;
        position: relative;

        label{
            font-size: 20px;
            font-weight: 100;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
            text-align: center;
            position: absolute;
            bottom: 2%
        }

        a{
            position: absolute;
            top: 2%;
            left: 5%;
            font-weight: 700;
            color: red;
            font-size: 20px;
        }
    }
`;

const DivCheckBox = styled(DivInput)`
    flex-direction: row;
    align-items: center;

    label{
        text-align: justify;
        font-size: 18px;
    }
`;

const DivGroupInput = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 750px){
        flex-direction: column;
    }
`;

const Submit = styled.div`
    margin: 5% 0%;
    width: 100%;

    #submit{
        width: 50%;
        font-size: 18px;
        border-radius: 100px;
        color: black;
        text-transform: uppercase;
        border: solid 1px black;
        border-radius: 100px;
        background: white;
        box-shadow: 10px 4px 8px black;
    }
    
    @media screen and (max-width: 750px){
        #submit{
            font-size: 15px;
        }
    }
`;

export default FormNewCustomer;