import React from "react";
import { Redirect } from "react-router";
import { AddFileProjectTemplate } from "../../components/templates";

const AddFileProject = props => {
    const { projectId, typeProject } = props.location.state;
    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404" />
    }else{
        return (
            <>
                <AddFileProjectTemplate projectId={ projectId } typeProject={ typeProject }/>
            </>
        )
    }
}

export default AddFileProject;