import React from "react";
import styled from "styled-components";
import { FormSignature } from "../../molecules";

const SignatureOrga = ({ projectId }) => {

    return(
        <Container>
            <FormSignature projectId={ projectId }/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    height: inherit;
`;

export default SignatureOrga;