import React from "react";
import { ErrorTemplate } from "../../components/templates";

const ErrorPage = props => {
    return (
        <> 
            <ErrorTemplate name={props.name}/>
        </>
    );
}

export default ErrorPage;