import React from "react";

const Input = (props) => {
    return(
        <>
            <input 
                value={props.value} 
                id={props.id} 
                type={props.type} 
                required={props.required} 
                disabled={props.disabled} 
                name={props.name} 
                style={props.style} 
                onChange={props.onChange} 
                multiple={props.multiple} 
                min={props.min} 
                max={props.max}
                accept={props.accept}
                placeholder={props.placeholder}
            />
        </>
    );
}

export default Input;