import React from "react";
import { Redirect } from "react-router";
import { ProjectsTemplate } from "../../components/templates";

const Projects = props => {
    let { status } = "";
    let techId = "0";
    if(props.location){
        if(props.location.state){
            if(props.location.state.techId){
                techId = props.location.state.techId;
            }
            if(props.location.state.status){
                status = props.location.state.status;
            }
        }
    }
    const tokenHeader = localStorage.getItem("tokenHeader");
    
    if(tokenHeader === null){
        return <Redirect to="/404"/>
    }else{
        return (
            <>
                <ProjectsTemplate status={ status } techId={ techId }/>
            </>
        )
    }
}

export default Projects;